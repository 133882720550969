import React, { Component, Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./ui/scss/style.scss";

import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import Auth from "context/Auth";
import UserInterface from "context/UserInterface";
import Editor from "context/Editor";
import ErrorFallback from "components/ErrorFallback";

import firebase from "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { PageLoading } from "components/Loading";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB1Aaj4uEHDbeIfdAyz45-hAnKyPxJHoDE",
  authDomain: "juno2-386821.firebaseapp.com",
  projectId: "juno2-386821",
  storageBucket: "juno2-386821.appspot.com",
  messagingSenderId: "99699785550",
  appId: "1:99699785550:web:86ebdb43b4985cee1d281f"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const loading = <PageLoading />;

// Containers
const DefaultLayout = React.lazy(() => import("./ui/layouts/DefaultLayout"));

const Login = React.lazy(() => import("./ui/views/login"));

const Zendesk = React.lazy(() => import("./ui/views/zendesk"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});
class App extends Component {
  logError(error) {
    console.error(error);
    // TODO: Do something with the error, e.g. log to an external API or logged using sentry
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={this.logError}
        >
          <Auth.Provider>
            <Toaster />
            <UserInterface.Provider>
              <RouterProvider
                router={createBrowserRouter([
                  {
                    path: "/login",
                    element: (
                      <Suspense fallback={loading}>
                        <Login />
                      </Suspense>
                    )
                  },
                  {
                    path: "/login/admin",
                    element: (
                      <Suspense fallback={loading}>
                        <Login />
                      </Suspense>
                    )
                  },
                  {
                    path: "/zendesk",
                    element: (
                      <Suspense fallback={loading}>
                        <Zendesk />
                      </Suspense>
                    )
                  },
                  {
                    path: "*",
                    element: (
                      <Suspense fallback={loading}>
                        <Editor.Provider>
                          <DefaultLayout />
                        </Editor.Provider>
                      </Suspense>
                    )
                  }
                ])}
              />
            </UserInterface.Provider>
          </Auth.Provider>
        </ErrorBoundary>
      </QueryClientProvider>
    );
  }
}

export default App;
