/**

Create a loading button

*/
import React, { useState } from "react";
import { CButton } from "@coreui/react";
import Measure from "react-measure";
import ReactLoading from "react-loading";

// import { KeyDetector } from "common";

const LoadingButton = (props) => {
  const [loading, setLoading] = useState(false);

  const [dimensions, setDimensions] = useState(null);

  let style = {};

  const addProps = {
    ...props
  };

  delete addProps.loading;
  delete addProps.loadOnEnter;
  delete addProps.loadingColor;

  const isDisabled = loading || props.loading || props.disabled;

  const onClick = async () => {
    if (isDisabled) {
      return;
    }

    if (props.loading !== false && props.loading !== true) {
      setLoading(true);
    }
    const result = props.onClick();

    // onClick response handling.
    if (result === false || (await result) === false) {
      setLoading(false);
    }
  };

  if (dimensions) {
    style = {
      width: Math.ceil(dimensions.width) + 1,
      height: Math.ceil(dimensions.height) + 1
    };

    return (
      <>
        <CButton
          {...addProps}
          onClick={onClick}
          disabled={isDisabled}
          style={style}
        >
          {!loading && !props.loading ? (
            props.children
          ) : (
            <center>
              <ReactLoading
                type="spinningBubbles"
                height={25}
                width={25}
                color={props.loadingColor || "#ffffff"}
              />
            </center>
          )}
        </CButton>
        {/* <KeyDetector.Component
          isDisabled={!props.loadOnEnter || isDisabled}
          useKey={KeyDetector.ENTER}
          onPress={onClick}
        /> */}
      </>
    );
  }

  if (!dimensions) {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          if (!dimensions) {
            setDimensions(contentRect.bounds);
          }
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} style={{ display: "inline-block" }}>
            <CButton {...addProps}>{props.children}</CButton>
          </div>
        )}
      </Measure>
    );
  }
};

export default LoadingButton;
