import Firestore from "models/Firestore";
import Brand from "models/Brand";
import { DEV } from "constants";

const COLLECTION_NAME = "partners";

class Partner extends Firestore {
  constructor(authContext) {
    super(COLLECTION_NAME, authContext);
  }

  /**
  Return object {partner_id, brand_id} for the current domain.
  */
  static async getPartnerDetailsFromDomain() {
    if (DEV) {
      return { partner_id: "DEV_PARTNER", brand_id: "DEV_BRAND" };
    }
    const hostName = window.location.hostname;
    const brandInstance = new Brand();
    const brand = await brandInstance.getBrandByDomainName(hostName);
    if (!brand) {
      return null;
    }
    const { partner_id, brand_id } = brand;
    return { partner_id, brand_id };
  }
}

export default Partner;
