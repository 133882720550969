import React from "react";

import ReactPaginate from "react-paginate";
import Consumer from "./Consumer";

import "./Paginator.css";

const Paginator = (props) => {
  const {
    isUpdating,
    rowCount, // number - how many rows there are total.
    simple, // show simple page selector
    resultsPerPage // number - how many rows per page.
  } = props;

  // When displaying page selector like:  < 1 >
  const simpleOptions = {
    pageRangeDisplayed: 0,
    marginPagesDisplayed: 0
  };

  // Consumer so we can get updateState.
  return (
    <Consumer>
      {({ state, updateState }) => {
        const displayStartNum = state.page * resultsPerPage + 1;
        let displayEndNum = state.page * resultsPerPage + resultsPerPage;

        if (displayEndNum > rowCount) {
          displayEndNum = rowCount;
        }

        return (
          <>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                updateState({ page: e.selected });
              }}
              {...(simple ? simpleOptions : {})}
              forcePage={state.page}
              pageCount={Math.ceil(props.pageCount)}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName={`pagination ${
                isUpdating ? "is-updating" : ""
              }`}
            />
            <p style={{ textAlign: "center" }}>
              {displayStartNum} - {displayEndNum} of {rowCount} results
            </p>
          </>
        );
      }}
    </Consumer>
  );
};

export default Paginator;
