/**
A modal that contains an editor provider and edit manager.
*/
import React from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CAlert,
  CRow,
  CCol
} from "@coreui/react";
import { Loading } from "components";

import Editor from "context/Editor";

const EditModal = (props) => {
  const {
    visible, // boolean - true to show the modal.
    onClose, // function called when the modal is closing.
    editManager, // Object - props passed to the <EditManager>
    /*
        data,
        getErrors,
        onSave
      */
    cModal // Object - props passed to the CModal
  } = props;

  return (
    <CModal
      alignment="center"
      visible={visible}
      onClose={() => {
        // Handles using the header X button. but also fires when visible changes state.
        onClose();
      }}
      {...cModal}
    >
      <Editor.Provider>
        <CModalHeader>{props.title}</CModalHeader>
        <CModalBody>
          <Editor.EditManager {...editManager}>
            {props.children}
          </Editor.EditManager>
        </CModalBody>
        <CModalFooter>
          <ModalFooterButtons onDiscard={onClose} />
        </CModalFooter>
      </Editor.Provider>
    </CModal>
  );
};

const ModalFooterButtons = (props) => {
  const { onDiscard } = props;
  const editor = Editor.useEditor();

  return (
    <CRow>
      <CCol className="v-center" style={{ justifyContent: "flex-end" }}>
        {editor.saveError ? (
          <span style={{ color: "red" }}>
            Error: {editor.saveError?.message || "unknown error."}
          </span>
        ) : null}
        {editor.isSaving ? <Loading /> : null}
      </CCol>
      <CCol xs="auto">
        <CButton
          color="light"
          style={{ marginRight: 10 }}
          onClick={() => {
            editor.doDiscard();
            onDiscard();
          }}
        >
          Cancel
        </CButton>
        <CButton
          color={editor.isEditing ? "primary" : "light"}
          disabled={editor.isEditing === false || editor.isSaving}
          onClick={() => {
            editor.doSave();
          }}
        >
          Save
        </CButton>
      </CCol>
    </CRow>
  );
};
export default EditModal;
