import React from "react";

import ReactLoading from "react-loading";

const Loading = (props) => (
  <ReactLoading
    type="spinningBubbles"
    height={props.height || 25}
    width={props.width || 25}
    color={props.color || "#016499"}
  />
);

export default Loading;

export const PageLoading = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "100vh" }}
  >
    <Loading height={50} width={50} color="#016499" />
  </div>
);
