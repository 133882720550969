import React from "react";

import { Line } from "react-chartjs-2";

const standardColors = [
  "rgba(255, 99, 132, 1)", // Red
  "rgba(75, 192, 192, 1)", // Green
  "rgba(54, 162, 235, 1)", // Blue
  "rgba(255, 206, 86, 1)", // Yellow
  "rgba(153, 102, 255, 1)", // Purple
  "rgba(255, 159, 64, 1)", // Orange
  "rgba(201, 203, 207, 1)" // Grey
];

/**
Javascript date to "2023-04-15"
*/
function dateToString(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

/**
 * Javascript date to hours, like "12am", "3pm"
 */
function dateToHours(date) {
  const hours = date.getHours();
  const isPM = hours >= 12;
  const hourIn12HourFormat = hours % 12 || 12;
  const amOrPm = isPM ? "pm" : "am";
  return `${hourIn12HourFormat}${amOrPm}`;
}

/**
 * Given an array, fill in missing dates with 0's.
 * list - Array<Object>
 * dateRange - Array<Date, Date> - start and end date range.
 * dailyOrHourly - string - "daily" or "hourly" breakdown
 * valueKey - string - the value to display on the chart
 * dateKey - string - name of the date key
 * groupByKey - string - column to group by
 * returns Object {dateList - array of all dates, dataSetsByGroup: {groupKey: {date: {...data}}}}
 */
const getDataSet = ({
  list,
  dateRange,
  dailyOrHourly,
  valueKey,
  dateKey,
  groupByKey
}) => {
  if (list.length < 1) {
    return list;
  }
  const dateList = [];
  let [minDate, maxDate] = dateRange;

  if (dailyOrHourly === "hourly") {
    // Only want data for 24 hours.
    maxDate = new Date(minDate);
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    maxDate.setSeconds(59);
  }

  let indexDate = new Date(minDate);
  while (indexDate < maxDate) {
    dateList.push(
      dailyOrHourly === "daily"
        ? dateToString(indexDate)
        : dateToHours(indexDate)
    );
    const nextDate = new Date(indexDate);
    if (dailyOrHourly === "daily") {
      nextDate.setDate(indexDate.getDate() + 1);
    } else {
      nextDate.setHours(indexDate.getHours() + 1);
    }
    indexDate = nextDate;
  }

  const dataSets = {};

  // Fill with all dates as keys and object as value
  // value object is set to 0
  const defaultDataSet = {};
  dateList.forEach((date) => {
    /* defaultDataSet[date] = {
      [valueKey]: 0
    }; */
    defaultDataSet[date] = 0;
  });
  list.forEach((item) => {
    const itemDate = item[dateKey];
    const groupValue = item[groupByKey];

    if (!dataSets[groupValue]) {
      dataSets[groupValue] = JSON.parse(JSON.stringify(defaultDataSet));
    }

    // if (!dataSets[groupValue][itemDate]) {
    //  dataSets[groupValue][itemDate] = {};
    // }
    dataSets[groupValue][itemDate] = Number(item[valueKey]);
    // dataSets[groupValue][itemDate][valueKey] = Number(item[valueKey]);
  });

  return { dateList, dataSetsByGroup: dataSets };
};

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgba(${r}, ${g}, ${b}, 1)`;
}

const DataLineChart = (props) => {
  const { isLoading, isUpdating, data, dateRange, error } = props;

  const {
    dailyOrHourly,
    valueKey, // value key to display
    dateKey, // key that contains the date.
    groupByKey, // key to group by
    groupLabel, // set the same label for all datasets. best to be used with a single dataset. if used, groupLabelExtator is not used.
    groupLabelExtractor // function to extract the label from a dataset. (groupKey // key from the group, data // all data from the query results. )
  } = props;

  // Use the date context to determine the start and ending date range.
  // If its one day, default to hourly.

  if (!data || data.length < 1) {
    return null;
  }

  const dataSet = getDataSet({
    list: data,
    dateRange,
    dailyOrHourly,
    valueKey,
    dateKey,
    groupByKey
  });

  const datasets = Object.entries(dataSet.dataSetsByGroup).map(
    ([groupKey, values], idx) => ({
        label: groupLabel || groupLabelExtractor(data, groupKey),
        data: dataSet.dateList.map((d) => values[d]),
        fill: false,
        borderColor: standardColors[idx],
        tension: 0.2
      })
  );

  const dataConfig = {
    labels: dataSet.dateList,
    datasets /* [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56],
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.2
      }
    ] */
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div>
      <Line data={dataConfig} options={options} />
    </div>
  );
};

export default DataLineChart;
