import React from "react";

import { CFormLabel, CFormInput } from "@coreui/react";

// Renders a label when isActive is false.
// Renders an input when isActive is true.
const InputOrLabel = (props) => {
  const { isActive, ...otherProps } = props;
  let displayLabel = null;

  if (otherProps.label) {
    // Need to show a label above the value label.
    displayLabel = (
      <CFormLabel className="form-label subtle" style={{ marginBottom: 2 }}>
        {otherProps.label}
      </CFormLabel>
    );
  }
  if (!isActive) {
    return (
      <>
        {displayLabel}
        <CFormLabel
          className="form-control"
          style={{ marginBottom: 25, padding: 0, border: "none" }}
        >
          {props.value || <i>not set</i>}
        </CFormLabel>
      </>
    );
  }

  return (
    <>
      <CFormInput
        {...otherProps}
        style={{ marginBottom: 25, ...otherProps.style }}
      />
    </>
  );
};

export default InputOrLabel;
