import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { DEV } from "constants";

class Firestore {
  // Override db to prefix DEV- collections.
  static get db() {
    const db = firebase.firestore();

    if (DEV) {
      // Override collection locking to the DEV- prefix.
      const _collection = db.collection.bind(db);
      db.collection = (collection) => {
        if (collection.startsWith("DEV-")) {
          return _collection(collection);
        }
        return _collection(`DEV-${collection}`);
      };
    }
    return db;
  }

  static get FieldValue() {
    return firebase.firestore.FieldValue;
  }
}

export default Firestore;
