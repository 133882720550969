import React from "react";

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) > 1 ? " years" : " year")
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) > 1 ? " months" : " month")
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + (Math.floor(interval) > 1 ? " days" : " day");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) > 1 ? " hours" : " hour")
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + (Math.floor(interval) > 1 ? " minutes" : " minute")
    );
  }
  return (
    Math.floor(seconds) + (Math.floor(seconds) > 1 ? " seconds" : " second")
  );
}

const TimeSince = (props) => {
  const {
    date,
    interval, // refresh interval.
    hideLabel // boolean - hide "ago"
  } = props;

  const [refresh, setRefresh] = React.useState(0);

  React.useEffect(() => {
    const i = setInterval(() => {
      setRefresh(Math.random());
    }, interval || 1000);
    return () => {
      clearInterval(i);
    };
  }, []);

  const since = timeSince(date);

  let showAgo = "ago";
  if (hideLabel) {
    showAgo = "";
  }

  return `${since} ${showAgo}`;
};

export default TimeSince;
