/**
 Display an image from GS.

 In development, files are added a prefix of DEV-
*/
import React from "react";
import { DEV } from "constants";

class StorageImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    this._load();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this._load();
    }
  }

  async _load() {
    if (!this.props.src) {
      return;
    }

    let devPrefix = "";

    if (DEV) {
      // Add the prefix.
      devPrefix = "DEV-";
    }

    try {
      /* const url = await firebase
        .storage()
        .refFromURL(
          `gs://mealplan-platform2.appspot.com/${devPrefix}${this.props.src}`
        )
        .getDownloadURL();
        */
      let width = `/width=${this.props.width}`;
      let height = `height=${this.props.height || "auto"}`;

      if (this.props.width && `${this.props.width}`.endsWith("%")) {
        width = "/width=500";
      }

      if (!this.props.width) {
        width = "/";
      }

      this.setState({
        url: `https://images.hetech.app/cdn-cgi/image${width},${height}/https://storage.googleapis.com/juno2-386821.appspot.com/${devPrefix}${this.props.src}`
      });
    } catch (e) {
      console.error(e);
      this.setState({
        url: null
      });
    }
  }

  render() {
    const { url } = this.state;

    const addProps = { ...this.props };

    delete addProps.emptyBlock;
    delete addProps.placeholder;

    if (this.props.type === "link") {
      return (
        <a href={url} {...addProps}>
          {this.props.children}
        </a>
      );
    }

    if (!url) {
      if (this.props.emptyBlock && this.props.width) {
        return <div style={{ width: Number(this.props.width) }} />;
      }
      if (this.props.placeholder) {
        return <img alt="" {...addProps} src={this.props.placeholder} />;
      }
      return null;
    }

    return <img alt="" {...addProps} src={url} />;
  }
}

export default StorageImage;
