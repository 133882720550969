import React, { useContext } from "react";

import { FirestoreCollection } from "components";

import Auth from "context/Auth";

const COLLECTION_NAME = "brands";

const BrandCollection = (props) => {
  const userAuth = Auth.useAuth();

  const filter = [].concat(userAuth.collectionFilter, props.filter || []);

  return (
    <FirestoreCollection
      {...props}
      filter={filter}
      collection={COLLECTION_NAME}
    />
  );
};

export default BrandCollection;
