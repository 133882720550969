/**
 *
 */
import React, { useState } from "react";

import Auth from "context/Auth";
import UserInterfaceContext from "./Context";


const UserInterfaceContextProvider = (props) => {
  const { user } = Auth.useAuth();

  // Show/side whole sidebar.
  const [sidebarVisible, setSidebarVisible] = useState(
    // user.role === "partner" ? true : false
    false
  );

  // Fold into just icons.
  const [sidebarFolded, setSidebarFolded] = useState(false);

  const value = {
    sidebarVisible,
    sidebarFolded,
    toggleSidebar: () => {
      setSidebarVisible(!sidebarVisible);
    },
    toggleSidebarFold: () => {
      setSidebarFolded(!sidebarFolded);
    },
    setSidebarVisible
  };

  return (
    <UserInterfaceContext.Provider value={value}>
      {props.children}
    </UserInterfaceContext.Provider>
  );
};

export default UserInterfaceContextProvider;
