/**
Display HTML from the RichEditor component.
*/
import React from "react";

import styles from "./RichEditorHtmlDisplay.module.css";

const RichEditorHtmlDisplay = ({ html }) => (
  <div
    className={styles.richEditor}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

export default RichEditorHtmlDisplay;
