class Stripe {
  static formatAmount(amount) {
    if (!amount) {
      return 0;
    }
    return (amount / 100).toFixed(2);
  }
}

export default Stripe;
