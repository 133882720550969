/**
* Manage the connection to the Editor context.
* This renders a child function to make editing easy:

props.children({
  data - original data with changes applied.
  onChange - function(key, newValue, removeKey) - mutate the data {key: newValue}
    - removeKey - true if the key should be removed.
  onDiscard - function to discard all changes.
  showErrors - true if errors should be shown
  errors - Object - output from props.getErrors.
})

*/
import { useContext, useState, useEffect } from "react";

import Context from "./Context";

const EditManager = (props) => {
  const {
    data, // Object to edit
    getErrors, // function(data) - return Object with keys as form ID's mapped to error strings.
    onSave, // function(changes) - run when the save button is clicked. throw new Error("message") will be handeled.
    onSaveSuccess // function() - run when onSave successfully executed
  } = props;

  const editor = useContext(Context);

  const [changes, setChanges] = useState({});

  const dataWithChanges = {
    ...(data || {})
    // ...changes
  };

  // Shallow merge objects.
  for (const key of Object.keys(changes)) {
    if (
      typeof dataWithChanges[key] === "object" &&
      !Array.isArray(dataWithChanges[key])
    ) {
      dataWithChanges[key] = {
        ...(dataWithChanges[key] || {}),
        ...changes[key]
      };
    } else {
      dataWithChanges[key] = changes[key];
    }
  }

  // Manage errors
  const errors = getErrors(dataWithChanges) || {};
  useEffect(() => {
    editor.setHasErrors(Object.keys(errors).length > 0);
  }, [errors]);

  // Compare the data when it changes.
  useEffect(() => {
    editor.compare(data || {}, dataWithChanges);
  }, [dataWithChanges]);

  // Handle discard
  useEffect(() => {
    if (!editor.discardKey) {
      return;
    }
    setChanges({});
    editor.setSaveKey(null);
  }, [editor.discardKey]);

  useEffect(() => {
    if (!editor.isEditing && editor.saveComplete && onSaveSuccess) {
      onSaveSuccess();
    }
  }, [editor.isEditing, editor.saveComplete]);

  // Handle saveKey changes.
  useEffect(() => {
    if (!editor.saveKey) {
      return;
    }
    const doSave = async () => {
      editor.setSaveError(null);
      editor.setIsSaving(true);

      let saveResult = null;

      try {
        saveResult = await onSave(changes);
      } catch (e) {
        editor.setSaveError(e);
        editor.setIsSaving(false);
        return;
      }
      if (saveResult === false) {
        editor.setDisableSuccessModal(true);
      }

      editor.setSaveComplete(true);
      editor.setShowErrors(false);
      setChanges({});
      // editor.setDisableSuccessModal(false);
    };
    doSave();
  }, [editor.saveKey]);

  const updateChanges = (key, value, removeKey) => {
    if (removeKey) {
      setChanges((prevState) => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
      return;
    }
    setChanges((prevState) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        return {
          ...prevState,
          [key]: { ...prevState[key], ...value }
        };
      }
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  return props.children({
    data: dataWithChanges,
    changes,
    showErrors: editor.showErrors,
    errors,
    onChange: updateChanges,
    onDiscard: editor.doDiscard
  });
};

export default EditManager;
