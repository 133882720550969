import { useEffect } from "react";

const useBeforeUnload = ({ show }) => {
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (show) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [show]);
};

export default useBeforeUnload;
