import FirestoreModel from "models/Firestore";
import Firestore from "utils/Firestore";

import Event from "models/Event";

const COLLECTION_NAME = "user_courses_cache";

class UserCourseCache extends FirestoreModel {
  constructor(authContext) {
    super(COLLECTION_NAME, authContext);
  }

  // Add a course id to the cache.
  add(userId, courseIdOrList) {
    const list = [].concat(courseIdOrList);

    return this.set(
      userId,
      {
        course_ids: this.FieldValue.arrayUnion(...list)
      },
      { merge: true }
    );
  }

  // Remove a course id from the cache.
  remove(userId, courseIdOrList) {
    const list = [].concat(courseIdOrList);
    return this.set(
      userId,
      {
        course_ids: this.FieldValue.arrayRemove(...list)
      },
      { merge: true }
    );
  }
}

export default UserCourseCache;
