/**
 *
 */
import React, { useState, useEffect } from "react";

import _ from "underscore";
import useBeforeUnload from "hooks/useBeforeUnload";
// eslint-disable-next-line import/no-cycle
import usePromptEditor from "hooks/usePromptEditor";
import EditorContext from "./Context";

const EditorContextProvider = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isForceEdit, setIsForceEdit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const [discardKey, setDiscardKey] = useState(null);
  const [saveKey, setSaveKey] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  const [disableSuccessModal, setDisableSuccessModal] = useState(false);

  useEffect(() => {
    if (!saveComplete) {
      return;
    }

    setIsSaving(false);
    setSaveKey(null);

    setIsEditing(false);

    setTimeout(() => {
      setSaveComplete(false);
    }, 1000);
  }, [saveComplete]);

  const showPrompt = isEditing && !saveComplete;
  useBeforeUnload({ show: showPrompt });
  usePromptEditor(
    showPrompt,
    "Changes you made may not be saved. Leave page?",
    () => setIsEditing(false)
  );

  const value = {
    isEditing,
    showErrors,
    setShowErrors,
    compare: (a, b) => {
      if (_.isEqual(a, b) && !isForceEdit) {
        setIsEditing(false);
        return;
      }
      // setSaveError(null);
      setIsEditing(true);
    },
    hasErrors,
    setHasErrors: (hasErrors) => {
      setHasErrors(hasErrors);
    },
    discardKey,
    doDiscard: () => {
      setHasErrors(false);
      setShowErrors(false);
      setSaveError(null);
      setDiscardKey(Math.random());
    },
    saveKey,
    doSave: () => {
      if (hasErrors) {
        setShowErrors(true);
        return;
      }
      setSaveKey(Math.random());
    },
    setIsEditing,
    isSaving,
    setIsSaving,
    setSaveKey,
    saveError,
    setSaveError,
    saveComplete,
    setSaveComplete,
    disableSuccessModal,
    setDisableSuccessModal,
    setIsForceEdit
  };

  return (
    <EditorContext.Provider value={value}>
      {props.children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
