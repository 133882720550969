/**
 *
 */
import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Firestore from "utils/Firestore";
import Event from "models/Event";
import User from "models/User";
import { PageLoading } from "components/Loading";
import UserAuthContext from "./Context";

import doLogin from "./functions/doLogin";
import processUser from "./functions/processUser";
import onUserSnapshot from "./functions/onUserSnapshot";

class UserAuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null, // the user document. with an id key added. this can change to spy on users.
      brand: null /* {
        id: "DEV_BRAND",
        name: "MyBrandName"
      } */, // if null, all brands for a partner. otherwise, locked to brand. all clients locked to a brand.
      collectionFilter: [
        // ["partner_id", "==", "DEV_PARTNER"]
        // ["brand_id", "==", "DEV_BRAND"]
      ], // How firestore collections should be filtered for the user.
      // For role=client - lock to partner and brand.
      // For role=partner - lock to partner, brand is optional
      // for role=admin - lock to none!
      authentication: {
        loading: true, // Loading the initial auth check.
        authorized: false, // user is authorized.
        error: false,
        errorMessage: null,
        errorCode: null, // error code from firestore auth.
        requiresPassword: false // does the account require a password. set after a login attempt, where the default password is wrong.
      },
      uid: "", // uid of the authed user.
      is_spying_on_user: false, // true when spying.
      partner: {
        logo: null, // gs file path to the logo.
        name: null // name of the partner business.
      } // partner information. available to partners only.
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(
      async (user) => {
        if (user) {
          if (this._authSuccess) {
            return;
          }

          this._authSuccess = true;
          // User is signed in
          const { uid } = user;

          Event.create({
            model_owner: "user",
            model_id: uid,
            event_category: "auth",
            event_name: "success"
          });

          try {
            const userModel = new User();
            await userModel.update(
              uid,
              {
                last_auth_at: Firestore.FieldValue.serverTimestamp()
              },
              true
            ); 
          } catch (error) {
            console.error(error);
          }
          this._unsubscribeSnapshot = onUserSnapshot(
            uid,
            async (userSnapshot) => {
              // user does not exist on firestore
              if(!userSnapshot.exists) {  
                await firebase.auth().signOut();
                return;
              }
              const newState = await processUser(this.state, userSnapshot);
              this.setState({ ...newState, uid }); // setting the uid only when the user auths.
            }
          );
        } else {
          // User is not authenticated
          this.setState({
            user: null,
            authentication: { loading: false, authorized: false }
          });
        }
      },
      (err) => console.log(`onAuthStateChanged error: ${err}`)
    );
  }

  componentWillUnmount() {
    if (this._unsubscribeSnapshot) {
      this._unsubscribeSnapshot();
    }
  }

  render() {
    // Loading to see if the user is already authenticated
    if (this.state.authentication.loading) {
      return <PageLoading />;
    }

    const value = {
      ...this.state,

      viewAsUser: (spyUid, callback) => {
        if (this.state.user.role !== "partner") {
          window.alert("You do not have permission!");
          return;
        }
        // console.log("view as user");
        // Unsubscribe the authed user document.
        this._unsubscribeSnapshot();
        // Subscribe to the spying user.
        this._unsubscribeSnapshot = onUserSnapshot(
          spyUid,
          async (userSnapshot) => {
            const newState = await processUser(this.state, userSnapshot);
            this.setState({ ...newState, is_spying_on_user: true }, callback);
          }
        );
      },
      exitAsUser: (callback) => {
        // Resubscribe the authenticated user.
        this._unsubscribeSnapshot();

        this._unsubscribeSnapshot = onUserSnapshot(
          this.state.uid,
          async (userSnapshot) => {
            const newState = await processUser(this.state, userSnapshot);
            this.setState({ ...newState, is_spying_on_user: false }, callback);
          }
        );
      },
      login: async (email, password, isPartnerRequest) => {
        const newAuthState = await doLogin(email, password, isPartnerRequest);

        if (!newAuthState) {
          // nothing returned from doLogin, that's success.
          return true;
        }
        // Some error happened.
        this.setState((state) => ({
          authentication: {
            ...state.authentication,
            ...newAuthState
          }
        }));
        return false;
      },
      logout: () => {
        this._authSuccess = false;
        this._unsubscribeSnapshot();
        return firebase.auth().signOut();
      }
    };

    return (
      <UserAuthContext.Provider value={value}>
        {this.props.children}
      </UserAuthContext.Provider>
    );
  }
}

export default UserAuthContextProvider;
