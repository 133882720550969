import React from "react";
import { CRow, CCol } from "@coreui/react";
import UserDocument from "models/User/Document";

const RenderSubscriptionCancelled = ({ row }) => {
  const metadata = JSON.parse(row.metadata || "{}");
  return (
    <CRow className="mb-2">
      <CCol xs={12}>
        {metadata?.cancelled_by_uid && (
          <p className="mb-2">
            Subscription cancelled by{" "}
            <UserDocument id={metadata?.cancelled_by_uid}>
              {({ isLoading, data }) => {
                if (isLoading || !data) {
                  return null;
                }
                if (!data.first_name && !data.last_name) {
                  return null;
                }
                return `${data.first_name} ${data.last_name}`;
              }}
            </UserDocument>
          </p>
        )}

        {metadata.cancellation_reason && (
          <p className="mb-2">
            Cancellation Reason: <strong>{metadata.cancellation_reason}</strong>
          </p>
        )}
      </CCol>
    </CRow>
  );
};

export default RenderSubscriptionCancelled;
