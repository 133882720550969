import React from "react";

const RenderAccountMergedIn = ({ row }) => {
  const metadata = JSON.parse(row.metadata || "{}");

  return (
    <p>
      User{" "}
      <a
        href={`/manage/users/${metadata.selected_uid}`}
        target="_blank"
        rel="noreferrer"
      >
        {metadata.selected_uid}
      </a>{" "}
      was merged into this account
    </p>
  );
};

export default RenderAccountMergedIn;
