const endOfDay = (date) => {
  const endDate = new Date(date);
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  return endDate;
};

const startOfDay = (date) => {
  const startDate = new Date(date);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  return startDate;
};

export default {
  endOfDay,
  startOfDay
};
