import React, { useContext } from "react";
import Context from "./Context";

export default function useEditor() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("useEditor() must be used within a Editor.Provider");
  }
  return context;
}
