import { PRESETS } from "./config";

/**
 * Return string - "Apr 2, 2023"
 */
const getDisplayString = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

const getDatePickerDisplayString = ({ start_date, end_date, preset }) => {
  // Apr 2, 2023 - Apr 3, 2023

  let display = `${getDisplayString(start_date)} - `;
  if (end_date) {
    display += `${getDisplayString(end_date)}`;
  }

  if (PRESETS[preset]) {
    if (preset === "today") {
      return `Today: ${getDisplayString(start_date)}`;
    } if (preset === "yesterday") {
      return `Yesterday: ${getDisplayString(start_date)}`;
    } if (preset === "last_hour") {
      return "Last hour";
    } if (preset === "all_time") {
      return "All time";
    }
    return `${PRESETS[preset]}: ${display}`;
  }

  return display;
};
export default getDatePickerDisplayString;
