/**
 * A link that is used to go back to a parent page.
 */

import { Link } from "react-router-dom";

import CIcon from "@coreui/icons-react";

import { cilChevronLeft } from "@coreui/icons";

const PageNavLink = (props) => (
    <p>
      <Link to={props.to}>
        <CIcon icon={cilChevronLeft} /> {props.children}
      </Link>
    </p>
  );

export default PageNavLink;
