/**
 * Given a preset name, return [startDate, endDate]
 * today [2023-04-07 0:0:0, 2023-04-07,23:59:59]
 */
const getDatesFromPreset = (preset) => {
  const today = new Date();

  const yesterday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  );

  if (preset === "last_hour") {
    const start_date = new Date(today.getTime() - 60 * 60 * 1000); // Subtract one hour (60 minutes * 60 seconds * 1000 milliseconds)
    const end_date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    );
    return [start_date, end_date];
  } if (preset === "today") {
    const start_date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
    const end_date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    );

    return [start_date, end_date];
  } if (preset === "yesterday") {
    const start_date = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      0,
      0,
      0
    );
    const end_date = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59
    );
    return [start_date, end_date];
  } if (preset === "last_7") {
    const sevenDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );

    const start_date = new Date(
      sevenDaysAgo.getFullYear(),
      sevenDaysAgo.getMonth(),
      sevenDaysAgo.getDate(),
      0,
      0,
      0
    );
    const end_date = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59
    );

    return [start_date, end_date];
  } if (preset === "last_14") {
    const sevenDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 14
    );

    const start_date = new Date(
      sevenDaysAgo.getFullYear(),
      sevenDaysAgo.getMonth(),
      sevenDaysAgo.getDate(),
      0,
      0,
      0
    );
    const end_date = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59
    );

    return [start_date, end_date];
  } if (preset === "last_30") {
    const sevenDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 30
    );

    const start_date = new Date(
      sevenDaysAgo.getFullYear(),
      sevenDaysAgo.getMonth(),
      sevenDaysAgo.getDate(),
      0,
      0,
      0
    );
    const end_date = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59
    );

    return [start_date, end_date];
  } if (preset === "this_week") {
    const now = new Date();

    // Calculate the start of the week (Sunday)
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    return [
      startOfWeek,
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      )
    ];
  } if (preset === "last_week") {
    const now = new Date();

    // Calculate the start of the last week (previous Sunday)
    const startOfLastWeek = new Date(now);
    startOfLastWeek.setDate(now.getDate() - now.getDay() - 7);
    startOfLastWeek.setHours(0, 0, 0, 0);

    // Calculate the end of the last week (previous Saturday)
    const endOfLastWeek = new Date(startOfLastWeek);
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
    endOfLastWeek.setHours(23, 59, 59, 999);

    return [startOfLastWeek, endOfLastWeek];
  } if (preset === "this_month") {
    const now = new Date();

    // Calculate the start of the current month (first day of the month)
    const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    startOfThisMonth.setHours(0, 0, 0, 0);

    return [
      startOfThisMonth,
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      )
    ];
  } if (preset === "last_month") {
    const now = new Date();

    // Calculate the start of the previous month (first day of the previous month)
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    startOfLastMonth.setHours(0, 0, 0, 0);

    // Calculate the end of the previous month (last day of the previous month)
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    endOfLastMonth.setHours(23, 59, 59, 999);

    return [startOfLastMonth, endOfLastMonth];
  } if (preset === "all_time") {
    return [
      new Date(1970, 0, 1, 0, 0, 0),
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      )
    ];
  }
  return null;
};

export default getDatesFromPreset;
