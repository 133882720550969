import React, { useState } from "react";

import { CRow, CCol } from "@coreui/react";

const MetadataRawDisplay = ({
  row, // the event row
  metadata, // the metadata to display
  toggle // boolean - should this show a link to toggle the display of the metadata. falsy and it will be shown by default.
}) => {
  const [visible, setVisible] = useState(false);

  if (toggle && !visible) {
    return (
      <CRow>
        <CCol>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setVisible(true);
            }}
          >
            View raw metadata
          </a>
        </CCol>
        <CCol xs="auto">Event ID: {row.id}</CCol>
      </CRow>
    );
  }

  return (
    <>
      <p>Event ID: {row.id}</p>
      <div
        style={{
          marginTop: 15,
          padding: 10,
          background: "#888",
          border: "1px solid #ccc",
          color: "#FFF",
          borderRadius: 5
        }}
      >
        <pre className="mb-0">{JSON.stringify(metadata, null, 2)}</pre>
      </div>
    </>
  );
};

export default MetadataRawDisplay;
