import React, { useState } from "react";

import { CButton } from "@coreui/react";

const CategoryButtons = (props) => {
  const {
    categories, // Array<String> of unique categories
    onFilterCategory
  } = props;

  const [filterCategories, setFilterCategories] = useState([]);

  const toggleCategoryFilter = (category) => {
    const newList = new Set(filterCategories);

    if (newList.has(category)) {
      newList.delete(category);
    } else {
      newList.add(category);
    }

    const newArray = [...newList];
    setFilterCategories(newArray);
    onFilterCategory(newArray);
  };

  const sorted = [].concat(categories);
  sorted.sort();

  return sorted.map((row, idx) => {
    const isFiltered = filterCategories.includes(row.event_category);

    return (
      <CButton
        key={idx}
        color={isFiltered ? "primary" : "secondary"}
        variant={isFiltered ? null : "outline"}
        style={{
          marginRight: 10,
          fontSize: 12
        }}
        onClick={() => {
          toggleCategoryFilter(row.event_category);
        }}
      >
        {row.event_category}
      </CButton>
    );
  });
};

export default CategoryButtons;
