import { useErrorBoundary } from "react-error-boundary";
import { CButton, CAlert, CRow, CCol } from "@coreui/react";

const ErrorFallback = ({ error }) => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="container-sm my-4">
      <div>
        <CRow style={{ justifyContent: "center" }}>
          <CCol xs={12} lg={6}>
            <CAlert color="danger">
              <strong>Something went wrong</strong>
              {error?.message && <p className="mb-0">{error.message}</p>}
            </CAlert>
            <div className="text-center">
              <CButton onClick={resetBoundary}>Try again</CButton>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  );
};

export default ErrorFallback;
