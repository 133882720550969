import React from "react";

const DataList = (props) => {
  const { isLoading, isUpdating, data, error, Component = "div" } = props;
  const {
    renderRow, // function(row) - array of renders for each column.
    renderNoData, // function -
    drillDownKey, // string - the key to drill down on, this preserves order on refresh (instead of using the row index). must match a column name.
    onDrillDownRender, // function(row) - when the row is toggled, this will render
    renderLoading
  } = props;

  return (
    <Component style={{ opacity: isUpdating ? 0.3 : 1 }}>
      {isLoading && renderLoading ? renderLoading() : null}
      {!isLoading && data.length < 1 ? renderNoData() : null}
      {data.map((row, idx) => {
        if (renderRow) {
          return <React.Fragment key={idx}>{renderRow(row)}</React.Fragment>;
        }
        return (
          <React.Fragment key={idx}>{Object.values(row)[0]}</React.Fragment>
        );
      })}
    </Component>
  );
};

export default DataList;
