import React from "react";

import { PRESETS } from "./config";

const PresetList = (props) => {
  const { selectPreset, selectedPreset } = props;

  return (
    <ul className="date-presets">
      {Object.entries(PRESETS).map(([key, value]) => (
          <li
            key={key}
            onClick={() => {
              selectPreset(key);
            }}
            style={{
              background: selectedPreset === key ? "#ccc" : null,
              fontSize: 12
            }}
          >
            {value}
          </li>
        ))}
    </ul>
  );
};

export default PresetList;
