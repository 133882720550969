import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useRef, useEffect } from "react";

const usePrompt = (onRouteChange, hasUnsavedChanges) => {
  const blocker = useBlocker(hasUnsavedChanges ? onRouteChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
};

export default usePrompt;
