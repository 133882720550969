import Brand from "models/Brand";
import Partner from "models/Partner";

/**
Process a user and return state for the provider.
*/
const processUser = async (state, userSnapshot) => {
  // Setup the state.

  const userDoc = userSnapshot.data();

  const newState = {
    // uid, // Never reset the uid. This allows us to fake users here different from the authed user. uid is always the authed user.
    // That's why we use the userSnapshot.id and set ID on the user document below:
    user: { id: userSnapshot.id, ...userDoc },
    authentication: {
      loading: false,
      authorized: true
    },
    collectionFilter: [["partner_id", "==", userDoc.partner_id]]
  };

  if (userDoc.role === "client") {
    newState.collectionFilter.push(["brand_id", "==", userDoc.brand_id]);

    // Load the brand.
    const brandModel = new Brand();
    const brandDocSnap = await brandModel.get(userDoc.brand_id);
    const brandDoc = brandDocSnap.data();

    newState.brand = {
      name: brandDoc.name,
      image: brandDoc.image,
      support_email: brandDoc.support_email,
      banner: brandDoc.banner
    };
  }

  if (userDoc.role === "partner") {
    // Reset to default.
    newState.brand = null;
    newState.collectionFilter = [["partner_id", "==", userDoc.partner_id]];

    // handle the user's selected_brand_id.
    // They should be locked to a brand if it exists.
    if (
      userDoc.selected_brand_id &&
      userDoc.selected_brand_id !== state.brand?.id
    ) {
      // Doc has a selected brand, and it's different from what was there.
      const brandModel = new Brand();
      const brandDocSnap = await brandModel.get(userDoc.selected_brand_id);
      if(!brandDocSnap.exists) {
        return;
      }
      const brandDoc = brandDocSnap.data();
      newState.brand = {
        id: userDoc.selected_brand_id,
        name: brandDoc.name,
        image: brandDoc.image
      };
      newState.collectionFilter = [
        ["partner_id", "==", userDoc.partner_id],
        ["brand_id", "==", userDoc.selected_brand_id]
      ];
      // console.log(newState.collectionFilter);
    } else if (userDoc.selected_brand_id) {
      newState.brand = state.brand;
      newState.collectionFilter = [
        ["partner_id", "==", userDoc.partner_id],
        ["brand_id", "==", userDoc.selected_brand_id]
      ];
    }

    const partnerModel = new Partner();
    const partnerDocSnap = await partnerModel.get(userDoc.partner_id);
    const partnerDoc = partnerDocSnap.data();

    newState.partner = { name: partnerDoc.name, image: partnerDoc.image };
  }

  return newState;
};

export default processUser;
