/*
 */
import React, { useState, useEffect } from "react";

import { DateSelection } from "components/Analytics";
import Context from "./Context";


const PaginationContextProvider = (props) => {
  const [state, setState] = useState({
    page: 0
  });

  const updateState = (newState) => {
    setState(newState);
  };

  return (
    <Context.Provider value={{ state, updateState }}>
      <DateSelection.Consumer>
        {({ state: dateState }) => (
            <DateStateHandler
              resetPage={() => {
                // console.log("reset page");
                updateState({ page: 0 });
              }}
              page={state.page}
              dateState={dateState}
            >
              {props.children}
            </DateStateHandler>
          )}
      </DateSelection.Consumer>
    </Context.Provider>
  );
};

const DateStateHandler = (props) => {
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!props.dateState) {
      return;
    }
    if (props.page < 1) {
      return;
    }
    props.resetPage();
  }, [props.dateState]);

  return props.children;
};

export default PaginationContextProvider;
