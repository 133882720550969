import User from "models/User";

/**
Listen for changes to a user.
uid - string - user's uid.
onSnapshot - function(userDocument) - run when user doc changes.
*/
const onUserSnapshot = (uid, onSnapshot) => {
  // Login success.
  // Load their user document.
  const userModel = new User();
  return userModel
    .docRef(uid)
    .onSnapshot(onSnapshot, (err) => console.log(`onUserSnapShot: ${err}`));
};

export default onUserSnapshot;
