export const DEV = process.env.NODE_ENV !== "production" && false;

export const SERVER = DEV
  ? "https://localhost:1110"
  : "https://j2backend.hetech.app";

export const COURSE_STATUS = ["draft", "published"];
export const INSTRUCTOR_STATUS = ["new", "active", "inactive"];
export const LESSON_STATUS = ["draft", "published"];
export const COURSE_TYPES = ["standard", "drip"];
export const COURSE_CLASS_TYPES = ["regular", "monthly", "none"];

export const ALGOLIA = {
  application_id: "EADA9K2R78",
  search_key: "2342495669f0441aac7d08953c8010e5"
};

// List of application names.
export const APPLICATIONS = [
  "Shopify",
  "Kajabi",
  "Stripe",
  "Cloudflare",
  "Klaviyo",
  "Samcart",
  "Paypal",
  "Zapier",
  "Seal"
];

export const USER_STATUSES = {
  ACTIVE: "active",
  ARCHIVED: "archived"
};

export const USER_ROLES = {
  CLIENT: "client",
  PARTNER: "partner"
};

export const integrationConfigKeys = {
  STRIPE: {
    SECRET_KEY: "stripe.secret_key"
  },
  SHOPIFY: {
    STORE_URL: "shopify.store_url",
    ACCESS_TOKEN: "shopify.access_token"
  },
  KLAVIYO: {
    API_KEY: "klaviyo.api_key"
  },
  PAYPAL: {
    CLIENT_ID: "paypal.client_id",
    SECRET_KEY: "paypal.secret_key"
  },
  SAMCART: {
    API_KEY: "samcart.api_key"
  }
};

export const applicationNames = {
  STRIPE: "stripe",
  SHOPIFY: "shopify",
  KLAVIYO: "klaviyo",
  PAYPAL: "paypal",
  SAMCART: "samcart"
};

export const GS_BASEURL =
  "https://storage.googleapis.com/juno2-386821.appspot.com";

export const SubscriptionStatusMap = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  CANCELLATION_IN_PROGRESS: "CANCELLATION IN PROGRESS",
  REACTIVATION_IN_PROGRESS: "REACTIVATION IN PROGRESS"
};
