/**
Render the metadata for any shopify event.

Handles all models/events.
*/
import React from "react";
import { CRow, CCol } from "@coreui/react";

import CourseDocument from "models/Course/Document";

const RenderPaypalMetadata = ({ row, metadata }) => {
  const { event_category, event_datetime, event_name } = row;

  // Default is to render as JSON.
  let renderContent = null;
  if (metadata) {
    renderContent = (
      <div
        style={{
          marginTop: 15,
          padding: 10,
          background: "#888",
          border: "1px solid #ccc",
          color: "#FFF",
          borderRadius: 5
        }}
      >
        <pre className="mb-0">{JSON.stringify(metadata, null, 2)}</pre>
      </div>
    );
  }
  if (event_category === "purchase" && event_name === "create") {
    renderContent = (
      <PurchasedList
        orderId={metadata?.purchaseInfo?.id}
        purchaseDate={metadata?.payload?.create_time}
        purchaseInfo={metadata?.purchaseInfo}
        courseIds={metadata?.course_ids}
        metadata={metadata}
      />
    );
  }

  return (
    <>
      <CRow className="mt-3">
        <CCol>{renderContent}</CCol>
      </CRow>
    </>
  );
};

export default RenderPaypalMetadata;

const PurchasedList = ({
  orderId,
  purchaseDate,
  purchaseInfo,
  courseIds,
  metadata
}) => {
  console.log(purchaseInfo);

  const listItems =
    purchaseInfo.type === "payment"
      ? purchaseInfo.transactions.map((transaction) => ({
          items: transaction.item_list.items.map((item) => ({
            name: item.name,
            price: item.price,
            currency: item.currency
          }))
        }))
      : purchaseInfo.purchase_units.map((unit) => ({
          items: unit.items.map((item) => ({
            name: item.name,
            price: item.unit_amount.value,
            currency: item.unit_amount.currency_code
          }))
        }));

  return (
    <>
      <CRow className="mb-2">
        <CCol>
          Order Id: <strong>{orderId}</strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase date:{" "}
          <strong>
            {new Date(purchaseDate).toLocaleDateString()}{" "}
            {new Date(purchaseDate).toLocaleTimeString("en-US", {
              hour: "numeric", // two-digit hour
              minute: "numeric", // two-digit minute
              hour12: true // use 12-hour clock (AM/PM)
            })}
          </strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase amount:{" "}
          <strong>
            ${metadata.payload.resource.amount.total}{" "}
            {metadata.payload.resource.amount.currency.toUpperCase()}
          </strong>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Products:
          {listItems.map((listItem, listItemIndex) => (
            <ul key={listItemIndex} className="mb-2">
              {listItem.items.map((item, index) => (
                <li key={`${listItemIndex}.${index}`}>
                  <PurchaseItem item={item} />
                </li>
              ))}
            </ul>
          ))}
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Assigned courses:
          <ul>
            {(courseIds || []).map((courseId) => (
              <li key={courseId}>
                <a
                  href={`/manage/courses/${courseId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CourseDocument id={courseId}>
                    {({ isLoading, data }) => {
                      if (isLoading || !data) {
                        return null;
                      }
                      return `${data.title}`;
                    }}
                  </CourseDocument>
                </a>
              </li>
            ))}
          </ul>
        </CCol>
      </CRow>
    </>
  );
};

const PurchaseItem = ({ item }) => (
  <>
    {item.name} - ${parseFloat(item.price).toFixed(2)}{" "}
    {item.currency.toUpperCase()}
  </>
);
