// BackToTop.js
import { CButton } from "@coreui/react";
import React, { useState, useEffect } from "react";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on scroll position
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {isVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "50px",
            right: "30px"
          }}
        >
          <CButton
            onClick={scrollToTop}
            style={{
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              opacity: "0.8",
              transition: "opacity 0.3s",
              zIndex: 1000
            }}
          >
            ↑ Back to Top
          </CButton>
        </div>
      )}
    </>
  );
};

export default BackToTop;
