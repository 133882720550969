/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/**
Render the metadata for an event.

Handles all models/events.
*/
import React, { useState } from "react";
import { CRow, CCol } from "@coreui/react";
import { Link } from "react-router-dom";
import CourseDocument from "models/Course/Document";

import MetadataRawDisplay from "./MetadataRawDisplay";

const RenderMetadata = (props) => {
  const {
    modelName,
    row,
    metadata,
    children // custom element to render children. will get passed all props.
  } = props;

  const {
    id,
    event_category,
    event_name,
    ip,
    user_agent,
    event_datetime
  } = row;

  let title = null;

  // Default is to render as JSON.
  let renderContent = null;

  if (event_category === "document") {
    if (event_name === "create") {
      title = <span>Created {modelName} document</span>;
    } else if (event_name === "update") {
      title = <span>Updated {modelName} document</span>;
    }
  }

  if (event_category === "course_access") {
    // course_access has course_ids
    if (event_name === "create") {
      title = "Assigned courses";
    } else if (event_name === "remove") {
      title = <p>Deleted course</p>;
    } else if (event_name === "bulk_remove") {
      title = <p>Deleted courses</p>;
    }
    title = <p>{title}</p>;
    renderContent = (
      <ul>
        {metadata?.course_ids?.map((courseId) => (
          <CourseDocument key={courseId} id={courseId}>
            {({ data }) => (
              <li>
                <Link to={`/manage/courses/${courseId}`} target="_blank">
                  {data?.title || <i>no name</i>}
                </Link>
              </li>
            )}
          </CourseDocument>
        ))}
      </ul>
    );
  }

  return (
    <>
      <CRow>
        <CCol>{title}</CCol>
      </CRow>
      <CRow>
        <CCol />
        <CCol xs="auto">
          {ip !== "0.0.0.0" ? ip : null}{" "}
          <UserAgentView userAgent={user_agent} />
        </CCol>
      </CRow>
      <CRow>
        <CCol>{renderContent}</CCol>
      </CRow>
      <CRow>
        <CCol>
          {children ? React.cloneElement(children, { ...props }) : null}
          <MetadataRawDisplay row={row} metadata={metadata} toggle />
        </CCol>
      </CRow>
    </>
  );
};

const UserAgentView = (props) => {
  const { userAgent } = props;

  if (!userAgent) {
    return;
  }

  const [visible, setVisible] = useState(false);

  return (
    <>
      &bull;{" "}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setVisible(!visible);
        }}
      >
        UA
      </a>
      {visible ? (
        <p>
          <small>{userAgent}</small>
        </p>
      ) : null}
    </>
  );
};

export default RenderMetadata;
