import { DEV } from "constants";

import Backend from "utils/Backend";

class Event {
  /**
  Create an event, or list of events.

  event - Object {
    model_owner
    model_id
    event_category
    event_name
    metadata
  }

  */
  static async create(singleEventOrEventList) {
    // Always convert to array.
    const eventList = [].concat(singleEventOrEventList);

    try {
      await Backend.post("events/create", {
        event_list: eventList.map((event) => {
          const newEvent = {
            ...event
          };
          // Set the time if it doesnt exist.
          if (!newEvent.event_datetime) {
            newEvent.event_datetime = new Date().toISOString();
          }
          return newEvent;
        }),
        is_dev: DEV
      });
    } catch (e) {
      // no errors allowed
    }
  }

  static async mergeUserEvents(original_uid, selected_uid) {
    await Backend.post("events/merge_user_events", {
      original_uid,
      selected_uid,
      is_dev: DEV
    });
  }
}

export default Event;
