import React from "react";

import { FirestoreCollection } from "components";

import Auth from "context/Auth";

const COLLECTION_NAME = "users";

const UserCollection = (props) => {
  const userAuth = Auth.useAuth();

  // let filter = [].concat(userAuth.collectionFilter, props.filter || []);

  return <FirestoreCollection {...props} collection={COLLECTION_NAME} />;
};

export default UserCollection;
