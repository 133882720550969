import usePrompt from "./usePrompt";

const usePromptEditor = (show, message, callback) => {
  const onRouteChange = () => {
    if (window.confirm(message)) {
      callback();
      return false;
    }
    return true;
  };

  usePrompt(onRouteChange, show);
};

export default usePromptEditor;
