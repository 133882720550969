import React from "react";

const RenderAccountHasMerged = ({ row }) => {
  const metadata = JSON.parse(row.metadata || "{}");

  return (
    <p>
      This account was merged into user:{" "}
      <a
        href={`/manage/users/${metadata.original_uid}`}
        target="_blank"
        rel="noreferrer"
      >
        {metadata.original_uid}
      </a>
    </p>
  );
};

export default RenderAccountHasMerged;
