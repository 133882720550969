import React, { useState, useEffect, forwardRef, useRef } from "react";

import DatePicker from "react-datepicker";

import { DateSelection } from "components/Analytics";

import getDatePickerDisplayString from "./getDatePickerDisplayString";
import getDatesFromPreset from "./getDatesFromPreset";
import adjustDate from "./adjustDate";
import determinePresetFromDates from "./determinePresetFromDates";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import PresetList from "./PresetList";

const DatePickerContextUpdater = (props) => {
  const { autoRefresh } = props;

  return (
    <DateSelection.Consumer>
      {({ state, updateState }) => (
          <DatePickerWithDateControl
            state={state}
            updateState={updateState}
            autoRefresh={autoRefresh}
          />
        )}
    </DateSelection.Consumer>
  );
};

const DatePickerWithDateControl = (props) => {
  const { state, updateState, autoRefresh } = props;

  const datePickerRef = useRef(null);

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  // Handle live updating.
  useEffect(() => {
    if (!autoRefresh) {
      return;
    }
    if (state.preset !== "last_hour" && state.preset !== "today") {
      return;
    }
    const interval = setInterval(() => {
      updateState({
        ...state,
        refresh_key: Math.random()
      });
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);

  const selectPreset = (name) => {
    const dates = getDatesFromPreset(name);
    updateState({
      start_date: dates[0],
      end_date: dates[1],
      preset: name,
      refresh_key: state.refresh_key
    });
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  const CustomInput = forwardRef((data, ref) => {
    const { value, onClick } = data;
    // console.log(state);

    return (
      <button
        className="example-custom-input"
        onClick={() => {
          // setDatePickerOpen(true);
          onClick();
        }}
        ref={ref}
        style={{
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
          fontSize: 14,
          borderRadius: 5,
          border: "1px solid #ccc"
        }}
      >
        {getDatePickerDisplayString(state)}
      </button>
    );
  });

  const MyContainer = ({ className, children }) => (
      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div className={className}>
          <div style={{ position: "relative" }}> {children}</div>
        </div>
        <div
          style={{
            background: "#FAFAFA",
            border: "1px solid #ccc",
            borderLeft: 0,
            borderRadius: 5,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0
          }}
        >
          <PresetList
            selectPreset={selectPreset}
            selectedPreset={state.preset}
          />
        </div>
      </div>
    );

  return (
    <DatePicker
      ref={datePickerRef}
      selected={state.start_date}
      onChange={([start, end]) => {
        let adjustedStartDate = start;
        let adjustedEndDate = end;

        if (start !== null) {
          adjustedStartDate = adjustDate.startOfDay(start);
        }
        if (
          end !== null &&
          state.preset !== "last_hour" &&
          state.preset !== "today"
        ) {
          adjustedEndDate = adjustDate.endOfDay(end);
        }

        const preset = determinePresetFromDates(
          adjustedStartDate,
          adjustedEndDate
        );

        const update = {
          start_date: adjustedStartDate,
          end_date: adjustedEndDate,
          preset,
          refresh_key: state.refresh_key
        };
        updateState(update);
      }}
      startDate={state.start_date}
      endDate={state.end_date}
      maxDate={new Date()}
      selectsRange
      monthsShown={2}
      customInput={<CustomInput />}
      calendarContainer={MyContainer}
      portalId="date-picker-portal-this-can-be-anything"
      onBlur={() => {
        setDatePickerOpen(false);
      }}
    />
  );
};

export default DatePickerContextUpdater;
