import Firestore from "models/Firestore";
import Event from "models/Event";
import User from "models/User";

import randomstring from "randomstring";

const COLLECTION_NAME = "brands";

class Brand extends Firestore {
  constructor(authContext) {
    super(COLLECTION_NAME, authContext);
  }

  /**
   * Create a brand
   */
  async create({ name }) {
    // Generate a random ID.
    // We don't want it too long, since it's prefixed to every auth email account.
    // 6 uppercase should do.
    const id = randomstring
      .generate({ length: 6, charset: "alphabetic" })
      .toUpperCase();

    // TODO: Verify/generate this server side to ensure uniqueness in the collection.

    const createDoc = {
      name,
      brand_id: id, // saving brand_id here keeps the model consistent with others
      partner_id: this.authContext.user.partner_id,
      created_at: this.FieldValue.serverTimestamp(),
      updated_at: this.FieldValue.serverTimestamp()
    };

    await this.set(id, createDoc);

    await Event.create({
      model_owner: "brand",
      model_id: id,
      event_category: "document",
      event_name: "create",
      metadata: createDoc
    });

    // Create the default cloudflare integration.
    const user = new User(this.authContext);
    await user.createIntegration({ name: "cloudflare", brand_id: id });
  }

  async update(brandId, changes) {
    await super.update(brandId, changes);
    await Event.create({
      model_owner: "brand",
      model_id: brandId,
      event_category: "document",
      event_name: "update",
      metadata: changes
    });
  }

  async getBrandByDomainName(domain) {
    const query = await this.colRef().where("domain_name", "==", domain).get();
    return query.docs.length > 0
      ? { ...query.docs[0].data(), id: query.docs[0].id }
      : null;
  }

  // TODO: hardcoded for now, since this is only use on public pages, e.g login page
  static getBrandByDomainName(domain) {
    if (domain === "my.better5.com") {
      return {
        name: "Better5",
        id: "KGVQJG",
      };
    }
    if (domain === "my.healthymom.club") {
      return {
        name: "Healthy Mom Club",
        id: "VCMPBZ"
      };
    }
    if (domain === "my.okanaganwineclub.com") {
      return {
        name: "Okanagan Wine Club",
        id: "AZHIIG"
      };
    }
  }
}

export default Brand;
