/*
Render a child function:

If you need to do things on the index directly (without using the aloglia supplied InstantSearch component), use the child function index param.

Object {
  __searchClient - algolia search client. don't use this directly and initialize an index as it might not be running on a development index.  Only used to setup the InstantSearch component.
  __indexName - name of the index, transalted for development
  index - search client initialized to the proper index
}

*/
import algoliasearch from "algoliasearch/lite";

import { DEV, ALGOLIA } from "constants";


const searchClient = algoliasearch(
  ALGOLIA.application_id,
  ALGOLIA.search_key
);

const SearchClient = (props) => {


  let { indexName } = props;

  if (DEV) {
    indexName = `DEV-${indexName}`;
  }

  return props.children({
    __searchClient: searchClient,
    __indexName: indexName,
    index: searchClient.initIndex(indexName)
  });
};

export default SearchClient;
