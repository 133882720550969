/**
Renders a card footer with save, discard, save and error confirmations.
*/

import React from "react";

import { CCardFooter, CButton, CRow, CCol } from "@coreui/react";
import { Loading } from "components";
import { useEditor } from "context/Editor";

const CardFooterEditorButtons = (props) => {
  const editor = useEditor();

  return (
    <CCardFooter>
      <CRow>
        {props.children}
        <CCol className="v-center" style={{ justifyContent: "flex-end" }}>
          {editor.isEditing && !editor.saveError && !editor.isSaving ? (
            <span>Unsaved changes...</span>
          ) : null}
          {editor.saveComplete ? (
            <span style={{ color: "green" }}>Saved!</span>
          ) : null}
          {editor.saveError ? (
            <span style={{ color: "red" }}>
              Error: {editor.saveError?.message || "unknown error."}
            </span>
          ) : null}
          {editor.isSaving ? <Loading /> : null}
        </CCol>
        <CCol xs="auto">
          <CButton
            color="light"
            style={{ marginRight: 10 }}
            disabled={editor.isEditing === false}
            onClick={() => {
              editor.doDiscard();
            }}
          >
            Discard
          </CButton>
          <CButton
            color={editor.isEditing ? "primary" : "light"}
            disabled={editor.isEditing === false || editor.isSaving}
            onClick={() => {
              editor.doSave();
            }}
          >
            Save
          </CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  );
};

export default CardFooterEditorButtons;
