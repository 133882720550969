/*
Drop a file and upload to google storage.

In development, files are prefixed with DEV- when stored in GS.
A non-prefixed file name is always returned.

File will be given a random name and prefixed with the provided folder.

Props:
- folder - folder prefix to place the image in. "main_folder/sub_folder"
- onComplete - function({fileName, path }) - a file has completed uploading.
- onDrop - function({fileName: {path, percent, complete}}). return a new uploadProgress state to mutate it. This allows for handling the same file name, if that's known outside the component.
- allowExtensions - Array<string> - allowed extensions.
- style - style added to parent div.
- disabled - boolean - disable and just render children.

Renders a child function with object:
{
  state: "ready",
  message: "user facing message",
  progress: {
    fileName: progress in whole number percent (like 50 for fifty percent).
  }
}
*/
import React, { useState, useCallback, useEffect } from "react";
import { DEV } from "constants";
import { Loading } from "components";

import randomstring from "randomstring";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { useDropzone } from "react-dropzone";

const getExtension = (fileName) => fileName.split(".").pop().toLowerCase();

const DDUploader = (props) => {
  const {
    allowExtensions,
    style,
    onComplete,
    onDrop,
    disabled,
    noClick
  } = props;

  const storage = firebase.storage();

  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState(null);
  const [isDropped, setIsDropped] = useState(false);
  const [complete, setComplete] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // accept: "image/*",
    noClick,
    onDrop: (acceptedFiles) => {
      // make sure all files meet the allowed extensions.
      const invalidExtensions = new Set();
      acceptedFiles.forEach((file) => {
        const extension = getExtension(file.name);
        if (allowExtensions && allowExtensions.includes(extension) === false) {
          invalidExtensions.add(extension);
        }
      });
      if (invalidExtensions.size > 0) {
        setError(
          `Invalid file extensions: [${[...invalidExtensions].join(",")}]`
        );
        return;
      }
      setError(null);
      setIsDropped(true);
      setComplete(false);

      let {folder} = props;
      // Folder needs to be "folder/subfolder/subfolder" - no prefix or trailing slash.
      if (folder.substr(0, 1) === "/") {
        folder = folder.substr(1);
      }
      if (folder.substr(0, folder.length - 1) === "/") {
        folder = folder.substr(0, folder.length - 1);
      }

      // Initialize the uploadProgress object.
      let uploadProgressDefault = {};
      acceptedFiles.forEach((file) => {
        const fileName = `${folder}/${randomstring.generate({
          length: 12
        })}.${getExtension(file.name)}`;

        uploadProgressDefault[file.name] = {
          path: fileName,
          percent: 0,
          complete: false,
          raw_file_upload: file
        };
      });

      if (onDrop) {
        const onDropResult = onDrop(uploadProgressDefault);
        if (onDropResult) {
          uploadProgressDefault = onDropResult;
        }
      }

      setUploadProgress(uploadProgressDefault);

      Object.entries(uploadProgressDefault).forEach(
        ([fileName, { path, raw_file_upload }]) => {
          // Add a dev prefix to the uploaded file, but always use the fileName without the prefix internally.
          let devPrefix = "";
          if (DEV) {
            devPrefix = "DEV-";
          }

          // const uploadPath = uploadProgressDefault[file.name].path;

          const uploadTask = storage
            .ref()
            .child(`${devPrefix}${path}`)
            .put(raw_file_upload);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Track the upload progress
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress((prevState) => ({
                ...prevState,
                [fileName]: { ...prevState[fileName], percent: progress }
              }));
            },
            (error) => {
              console.error("Upload failed:", error);
              setError(error);
            },
            () => {
              // complete.
              // Reset the upload progress to 100%
              setUploadProgress((prevState) => {
                const newState = {
                  ...prevState,
                  [fileName]: {
                    ...prevState[fileName],
                    percent: 100,
                    complete: true
                  }
                };

                return newState;
              });
            }
          );
        }
      );
    }
  });

  useEffect(() => {
    if (complete) {
      // Already run!
      return;
    }
    if (!uploadProgress) {
      // not running.
      return;
    }
    if (!onComplete) {
      return;
    }
    // See if all are complete.
    const numComplete = Object.values(uploadProgress).filter(
      ({ complete }) => complete
    ).length;
    const totalItems = Object.values(uploadProgress).length;

    if (numComplete >= totalItems) {
      onComplete(uploadProgress);
      setComplete(true);
      setIsDropped(false);
      setUploadProgress(null);
    }
  }, [uploadProgress, complete]);

  if (disabled) {
    return props.children();
  }

  let state = "ready";
  let message = "drop file or click here";

  if (error) {
    state = "error";
    message = error;
  }
  if (isDropped) {
    state = "uploading";
    message = `uploading`;
  }
  /*
  if (complete) {
    state = "complete";
    message = "upload complete";
  }
  */
  if (isDragActive) {
    state = "drag_active";
    message = "drop to upload";
  }

  return (
    <div {...getRootProps()} style={style}>
      <input {...getInputProps()} />
      {props.children({ state, message, progress: uploadProgress })}
    </div>
  );
}

export default DDUploader;
