/*
Highlight an attribute

Props:
- hit - object - the hit from the search
- attribute - string - the attribute to highlight
*/
import React from "react";

import { connectHighlight } from "react-instantsearch-dom";

const Highlight = connectHighlight(
  ({ highlight, attribute, hit, highlightProperty }) => {
    const parsedHit = highlight({
      attribute,
      hit,
      highlightProperty: "_highlightResult"
    });
    const highlightedHits = parsedHit.map((part, idx) => {
      if (part.isHighlighted) return <mark key={idx}>{part.value}</mark>;
      return part.value;
    });
    return highlightedHits;
  }
);
export default Highlight;
