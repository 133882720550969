import Firestore from "utils/Firestore";

class FirestoreData {
  constructor(collectionName, authContext) {
    this.collection = Firestore.db.collection(collectionName);
    this.authContext = authContext;
  }

  get FieldValue() {
    return Firestore.FieldValue;
  }

  batch() {
    return Firestore.db.batch();
  }

  colRef() {
    return this.collection;
  }

  docRef(id) {
    return this.collection.doc(id);
  }

  add(doc) {
    return this.colRef().add(doc);
  }

  get(id) {
    return this.docRef(id).get();
  }

  exists(id) {}

  set(id, data, options) {
    return this.docRef(id).set(data, options);
  }

  update(id, data) {
    return this.docRef(id).update(data);
  }

  /**
   * update multiple documents in a batch.
   * idChangesMap - Object { documentId: documentChanges }
   */
  updateBatch(idChangesMap) {
    const batch = this.batch();
    Object.entries(idChangesMap).forEach(([id, changes]) => {
      batch.update(this.docRef(id), changes);
    });
    return batch.commit();
  }

  permaDelete(id) {
    return this.docRef(id).delete();
  }
}

export default FirestoreData;
