import React from "react";
import { CFormSelect } from "@coreui/react";
import { FirestoreCollection } from "components";

import Auth from "context/Auth";

/**
Query a collection and render as a Select.

This forces the userAuth collection_filter. So it will be locked to partner/brand.

*/
const CollectionSelect = (props) => {
  const {
    value,
    collection, // collection name
    filter,
    getLabel, // function(document) - return a label string to display in the select.
    firstLabel, // string - first label to show. optional.
    formLabel, // string - passed to CFormSelect to display a form label.
    onChange, // function(objectID) - function called with the ID of the selected document.
    invalid, // passed to CFormSelect
    feedbackInvalid, // passed to CFormSelect
    clientFilter // function(document) return true to pass the filter. happens on client side documents, not firestorecollection filter.
  } = props;

  const userAuth = Auth.useAuth();

  const useFilter = [].concat(userAuth.collectionFilter, filter || []);

  return (
    <FirestoreCollection
      {...props}
      filter={useFilter}
      onSnapshot={(snapshot) => {}}
    >
      {({ isLoading, data }) => {
        if (isLoading) {
          // Show a select while it loads.
          return (
            <CFormSelect
              options={[{ label: firstLabel, value: "false" }]}
              value=""
              label={formLabel}
              invalid={invalid}
              readOnly
              feedbackInvalid={feedbackInvalid}
            />
          );
        }

        let options = [];
        if (firstLabel) {
          options.push({ label: firstLabel, value: "false" });
        }

        let filteredData = [].concat(data);
        if (clientFilter) {
          filteredData = filteredData.filter(clientFilter);
        }

        options = [].concat(
          options,
          filteredData.map((doc) => ({
              label: getLabel(doc),
              value: doc.id
            }))
        );

        // If there's only one option, readOnly it.
        return (
          <CFormSelect
            options={options}
            value={value}
            onChange={(e) => {
              const val = e.target.value;
              if (val === "false") {
                onChange("");
                return;
              }
              onChange(e.target.value);
            }}
            label={formLabel}
            invalid={invalid}
            feedbackInvalid={feedbackInvalid}
          />
        );
      }}
    </FirestoreCollection>
  );
};

export default CollectionSelect;
