/**
Render the metadata for any shopify event.

Handles all models/events.
*/
import React from "react";
import { CRow, CCol } from "@coreui/react";

const RenderSamcartMetadata = ({ modelName, row, metadata }) => {
  const { event_category, event_datetime, event_name } = row;

  // Default is to render as JSON.
  let renderContent = null;

  if (event_category === "purchase" && event_name === "create") {
    renderContent = (
      <PurchasedList
        purchaseDate={metadata?.payload?.order?.created_at}
        products={metadata?.payload?.products}
        order={metadata?.payload?.order}
      />
    );
  }

  return (
    <>
      <CRow className="mt-3">
        <CCol>{renderContent}</CCol>
      </CRow>
    </>
  );
};

export default RenderSamcartMetadata;

const PurchasedList = ({ order, purchaseDate, products = [] }) => {
  const orderNumber = order.id;

  return (
    <>
      <CRow className="mb-2">
        <CCol>
          Order Number: <strong>{orderNumber}</strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase date:{" "}
          <strong>
            {new Date(purchaseDate).toLocaleDateString()}{" "}
            {new Date(purchaseDate).toLocaleTimeString("en-US", {
              hour: "numeric", // two-digit hour
              minute: "numeric", // two-digit minute
              hour12: true // use 12-hour clock (AM/PM)
            })}
          </strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase amount: <strong>${order.total} USD</strong>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Products:
          <ul>
            {products.map((product) => (
              <li key={product.id}>
                {product.name} - ${parseFloat(product.price).toFixed(2)} USD
              </li>
            ))}
          </ul>
        </CCol>
      </CRow>
    </>
  );
};
