/**
Display the name and link to various documents based on model_owner and model_id.
*/
import React from "react";

import UserDocument from "models/User/Document";
import CourseDocument from "models/Course/Document";

const ModelDocumentDetails = (props) => {
  const { model_owner, model_id } = props;

  let doc = null;
  let link = "";

  if (model_owner === "user") {
    link = `/manage/users/${model_id}`;
    doc = (
      <UserDocument id={model_id}>
        {({ isLoading, data }) => {
          if (isLoading || !data) {
            return null;
          }

          if (!data.first_name && !data.last_name) {
            return null;
          }

          return `${data.first_name} ${data.last_name}`;
        }}
      </UserDocument>
    );
  } else if (model_owner === "course") {
    link = `/manage/courses/${model_id}`;
    doc = (
      <CourseDocument id={model_id}>
        {({ isLoading, data }) => {
          if (isLoading || !data) {
            return null;
          }
          return `${data.title}`;
        }}
      </CourseDocument>
    );
  }

  if (!doc) {
    return null;
  }

  return (
    <>
      <a href={link} target="_blank" rel="noreferrer">
        {doc}
      </a>{" "}
      &bull;{" "}
    </>
  );
};

export default ModelDocumentDetails;
