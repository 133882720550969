/**
A simple rich editor
*/
import React, { useState, useEffect } from "react";

import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";

import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/text-alignment/lib/plugin.css";

import "./RichEditor.css"; // Import your custom styles

import {
  ItalicButton,
  BoldButton,
  UnorderedListButton,
  OrderedListButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton
} from "@draft-js-plugins/buttons";

const RichEditor = (props) => {
  const {
    value, // html value
    onChange // function(htmlString) to change the content
  } = props;

  const [initialValue, setInitialValue] = useState(value);

  const [editorState, setEditorState] = React.useState(() => {
    if (value) {
      return EditorState.createWithContent(stateFromHTML(value));
    }
    return EditorState.createEmpty();
  });

  const [{ plugins, Toolbar }] = useState(() => {
    const textAlignmentPlugin = createTextAlignmentPlugin();

    const toolbarPlugin = createToolbarPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, textAlignmentPlugin];
    return {
      plugins,
      Toolbar
    };
  });

  useEffect(() => {
    if (!value && editorState.getCurrentContent().getPlainText() !== "") {
      setEditorState(EditorState.createEmpty());
      return;
    }
    if (value && value !== stateToHTML(editorState.getCurrentContent())) {
      setEditorState(EditorState.createWithContent(stateFromHTML(value)));
    }
  }, [value, editorState]);

  const handleEditorChange = (newState) => {
    setEditorState(newState);

    const html = stateToHTML(newState.getCurrentContent());

    // Empty html comes through as <p><br></p>

    if (!value && html === "<p><br></p>") {
      return;
    }

    if (html === "<p><br></p>") {
      props.onChange("");
    } else if (html !== value) {
      props.onChange(html);
    }
  };

  return (
    <div
      style={{
        border: "1px solid #d8dbe0",
        borderRadius: "0.3rem",
        padding: 5,
        cursor: "text"
      }}
    >
      <Editor
        editorState={editorState}
        onChange={handleEditorChange}
        plugins={plugins}
        handleKeyCommand={(command, currentEditorState) => {
          // Don't allow the user to underline.
          if (command === "underline") {
            return "not-handled";
          }

          // inline formatting key commands handles bold, italic, code, underline
          const newEditorState = RichUtils.handleKeyCommand(
            currentEditorState,
            command
          );

          if (newEditorState) {
            handleEditorChange(newEditorState);
            return "handled";
          }

          return "not-handled";
        }}
      />
      <div style={{ marginTop: 25 }}>
        <Toolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
            </>
          )}
        </Toolbar>
      </div>
    </div>
  );
};

export default RichEditor;
