import React, { useState, useEffect } from "react";

import Backend from "utils/Backend";

const EventsQuery = (props) => {
  const {
    select, // string - what comes after the word, "select": "sum(column_a), column_b"
    where, // string - after the where "column_a = 1"
    groupBy, // string - after the "group by", "column_a"
    having, // string - having statement.
    orderBy,
    limit,
    offset,
    parentQuery, // string - original query will be wrapped with this parentQuery ( ...originalSelect )
    onLoad
  } = props;

  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const onLoadObject = {
        error,
        data,
        isLoading: loading,
        isUpdating
      };

      try {
        setError(null);
        setIsUpdating(true);
        const response = await Backend.post("events/query", {
          select,
          where,
          group_by: groupBy,
          order_by: orderBy,
          having,
          limit,
          offset,
          parentQuery
        });
        setData(response.data);
        onLoadObject.data = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        onLoadObject.error = error;
      } finally {
        setLoading(false);
        setIsUpdating(false);
        onLoadObject.isLoading = false;
        onLoadObject.isUpdating = false;
      }

      if (onLoad) {
        onLoad(onLoadObject);
      }
    };

    fetchData();
  }, [props]);

  const obj = {
    isLoading: loading,
    isUpdating,
    data,
    error
  };

  return props.children(obj);
};

export default EventsQuery;
