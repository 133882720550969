/**
 * Wrap EventsQuery with the DateSelectionContext.
 */
import React from "react";

import { DateSelection, Pagination } from "components/Analytics";
import EventsQuery from "./EventsQuery";


const EventsQueryControlled = (props) => (
    <DateSelection.Consumer>
      {({ state }) => (
          <Pagination.Consumer>
            {({ state: paginationState }) => (
                <QueryHandler
                  {...props}
                  dateState={state}
                  page={paginationState?.page}
                />
              )}
          </Pagination.Consumer>
        )}
    </DateSelection.Consumer>
  );

/**
 * dateA - Date
 * dateB - Date
 * Return boolean - is dateA and dateB the same date.
 */
const isSameDay = (dateA, dateB) => {
  if (!dateA || !dateB) {
    return false;
  }

  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  );
};

function shallowCompare(obj1, obj2) {
  // Check if the number of keys is the same
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // Check if the values of each key are the same
  for (const key in obj1) {
    if (typeof obj1[key] === "function") {
      continue;
    }
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

// Memoize because we do not want to rerender the query when date ranges are being selected.
// When dates are being selected the end_date is null.
const QueryHandler = React.memo(
  (props) => {
    const {
      select, // select string.
      // %dynamic_event_datetime% - replaced with a date (2023-04-21) or hourly, breakdown.
      // %event_datetime% - timezone adjusted event_datetime,
      dateState, // dateState from the DateSelectionContext
      where, // where sql string.
      page,
      forceHourlyListing, // boolean - force listing by hour.
      forceDailyListing,
      dynamicTime // boolean - replace %event_datetime% with daily or hourly breakdowns.
    } = props; // other props are passed straight to EventsQuery

    if (!select) {
      throw "EventsQueryControlled > QueryHandler : Missing select";
    }

    let newWhere = "";
    if (where) {
      // add the where and prep to combine with date restriction.
      newWhere = `(${props.where}) and `;
    }

    if (dateState?.timezone) {
      newWhere += `toTimeZone(event_datetime, '${
        dateState.timezone
      }') BETWEEN toTimeZone(toDateTime('${
        dateState.start_date.toISOString().split(".")[0]
      }'), '${dateState.timezone}') AND toTimeZone(toDateTime('${
        dateState.end_date.toISOString().split(".")[0]
      }'), '${dateState.timezone}')`;
    }

    let {offset} = props;

    if (!offset && props.limit && page) {
      offset = page * props.limit;
    }

    let listByDate = true;

    if (isSameDay(dateState.start_date, dateState.end_date)) {
      listByDate = false;
    }

    if (forceHourlyListing) {
      listByDate = false;
    }

    if (forceDailyListing) {
      listByDate = true;
    }

    // Replace %event_datetime% with toTimeZone(event_datetime,dateState.timezone)
    let newSelect = select;

    if (dynamicTime && !listByDate) {
      // breakdown by hourly.
      newSelect = newSelect.replace(
        "%dynamic_event_datetime%",
        `CONCAT(
toString(
    if(
        toHour(toTimeZone(event_datetime,'${dateState.timezone}')) = 0 OR toHour(toTimeZone(event_datetime,'${dateState.timezone}')) = 12,
        12,
        modulo(toHour(toTimeZone(event_datetime,'${dateState.timezone}')), 12)
    )
),
if(
    toHour(toTimeZone(event_datetime,'${dateState.timezone}')) < 12,
    'am',
    'pm'
)
)`
      );
    } else if (dynamicTime) {
      // breakdown daily.
      newSelect = newSelect.replace(
        "%dynamic_event_datetime%",
        `toDate(toTimeZone(event_datetime,'${dateState.timezone}'))`
      );
    }

    // Always convert %event_datetime% to timezone adjusted value.
    newSelect = newSelect.replace(
      "%event_datetime%",
      `toTimeZone(event_datetime,'${dateState.timezone}') as event_datetime`
    );

    return (
      <EventsQuery
        {...props}
        select={newSelect}
        where={newWhere}
        offset={offset}
      >
        {(obj) => {
          if (!props.children) {
            return null;
          }
          return props.children({
            ...obj,
            dateRange: [dateState.start_date, dateState.end_date]
          });
        }}
      </EventsQuery>
    );
  },
  (prevProps, nextProps) => {
    // Returns true to prevent rendering if any of the dateState is null.
    if (
      nextProps.dateState.start_date === null ||
      nextProps.dateState.end_date === null
    ) {
      // true to not rerender.
      return true;
    }

    if (
      nextProps.dateState.start_date.getTime() !==
        prevProps.dateState.start_date.getTime() ||
      nextProps.dateState.end_date.getTime() !==
        prevProps.dateState.end_date.getTime()
    ) {
      // console.log(prevProps.page, nextProps.page);
      // start or end dates have changed.
      // see if the page is used, and has changed.
      if (
        (nextProps.page > 0 || prevProps.page > 0) &&
        nextProps.page === prevProps.page
      ) {
        // date change and page matched.
        // block this render. so the next render with page update only runs.
        return true;
      }
    }
    const compare = shallowCompare(prevProps, nextProps);

    return compare;
  }
);

export default EventsQueryControlled;
