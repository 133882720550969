/**
 * Date selection provider
 */
import React, { useState } from "react";

import Context from "./Context";

import getDatesFromPreset from "./Picker/getDatesFromPreset";

const DateSelectionContextProvider = (props) => {
  const today = new Date();

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const defaultState = {
    start_date: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    end_date: new Date(),
    preset: "today",
    timezone: timeZone || "America/Vancouver",
    refresh_key: null
  };

  if (props.initialPreset) {
    const [start, end] = getDatesFromPreset(props.initialPreset);
    defaultState.start_date = start;
    defaultState.end_date = end;
    defaultState.preset = props.initialPreset;
  }

  const [state, setState] = useState(defaultState);

  const updateState = (newState) => {
    setState((state) => ({ ...state, ...newState }));
  };

  return (
    <Context.Provider value={{ state, updateState }}>
      {props.children}
    </Context.Provider>
  );
};

export default DateSelectionContextProvider;
