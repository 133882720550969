/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/*
Display a card showing events for a Model.
*/
import React, { useState, useContext } from "react";

import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardHeader,
  CFormSelect
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { cisUser, cisCog } from "@coreui/icons-pro";

// eslint-disable-next-line import/no-cycle
import { Analytics, TimeSince } from "components";
import UserDocument from "models/User/Document";
import ModelEventsContext from "./Context";
import RenderStripeMetaData from "./RenderStripeMetadata";
import RenderMetadata from "./RenderMetadata2";
import RenderShopifyMetadata from "./RenderShopifyMetaData";
import CategoryButtons from "./CategoryButtons";
import RenderSamcartMetadata from "./RenderSamcartMetadata";
import RenderPaypalMetadata from "./RenderPaypalMetadata";

import RenderAccountMergedIn from "./RenderAccountMergedIn";
import RenderAccountHasMerged from "./RenderAccountHasMerged";
import RenderCourseAlreadyGranted from "./RenderCourseAlreadyGranted";

import ModelDocumentDetails from "./ModelDocumentDetails";
import RenderSubscriptionCancelled from "./RenderSubscriptionCancelled";

export const ActivityCardBody = ({ modelName, row }) => {
  const jsMetadata = row.metadata || "{}";

  const metadata = JSON.parse(jsMetadata);

  let metadataRenderer = null;

  if (metadata?.applicationType === "stripe") {
    metadataRenderer = (
      <RenderStripeMetaData
        metadata={metadata}
        row={row}
        modelName={modelName}
      />
    );
  }

  if (metadata?.applicationType === "shopify") {
    metadataRenderer = (
      <RenderShopifyMetadata
        metadata={metadata}
        row={row}
        modelName={modelName}
      />
    );
  }

  if (metadata?.applicationType === "samcart") {
    metadataRenderer = (
      <RenderSamcartMetadata
        metadata={metadata}
        row={row}
        modelName={modelName}
      />
    );
  }

  if (metadata?.applicationType === "paypal") {
    metadataRenderer = (
      <RenderPaypalMetadata
        metadata={metadata}
        row={row}
        modelName={modelName}
      />
    );
  }

  if (row.event_category === "old_user_merged_in") {
    metadataRenderer = <RenderAccountMergedIn row={row} />;
  }

  if (row.event_category === "account_merged") {
    metadataRenderer = <RenderAccountHasMerged row={row} />;
  }

  if (
    row.event_category === "warning" &&
    row.event_name === "order create" &&
    row.message === "Course already granted"
  ) {
    metadataRenderer = <RenderCourseAlreadyGranted row={row} />;
  }

  if (
    ["client", "partner"].includes(row.role) &&
    row.event_category === "subscription" &&
    row.event_name === "cancelled"
  ) {
    metadataRenderer = <RenderSubscriptionCancelled row={row} />;
  }

  if (metadata?.is_migration) {
    metadataRenderer = null;
  }

  return (
    <CCardBody>
      <RenderMetadata metadata={metadata} row={row} modelName={modelName}>
        {metadataRenderer}
      </RenderMetadata>
    </CCardBody>
  );
};

export const Row = ({ modelName, modelId, row }) => {
  const [showDetails, setShowDetails] = useState(false);

  const state = useContext(ModelEventsContext);

  return (
    <CCard className="mb-3">
      <CCardHeader
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        <CRow>
          {row.uid && (
            <UserDocument id={row.uid}>
              {({ isLoading, data }) => {
                if (isLoading || !data) {
                  return null;
                }

                let text = "";
                // Applications have just a name
                if (data.name) {
                  text = `Application: ${data.name}`;
                } else {
                  text = `${data.first_name} ${data.last_name}`;
                }

                // For clients, link directly to them.
                if (row.role === "client") {
                  text = <a href={`/manage/users/${row.uid}`}>{text}</a>;
                }

                let renderIcon = (
                  <CIcon
                    icon={cisUser}
                    className={
                      data.role === "partner"
                        ? "text-primary"
                        : "text-secondary"
                    }
                    style={{ marginRight: 10 }}
                  />
                );

                if (data.role === "integration") {
                  renderIcon = (
                    <CIcon
                      icon={cisCog}
                      className="text-secondary"
                      style={{ marginRight: 10 }}
                    />
                  );
                }

                return (
                  <>
                    <CCol xs={3}>
                      {renderIcon}
                      {text}
                    </CCol>
                  </>
                );
              }}
            </UserDocument>
          )}

          <CCol>
            {row.role !== "client" ? <ModelDocumentDetails {...row} /> : null}
            {row.model_owner} &bull; {row.event_category} &bull;{" "}
            {row.event_name}
            {row.message && <> &bull; {row.message}</>}
          </CCol>
          <CCol xs="auto">
            <p className="mb-0">
              <small>
                {new Date(row.event_datetime).toLocaleString("en-US", {
                  month: "short", // "short" for abbreviated month name (e.g., "Feb")
                  day: "numeric", // numeric day
                  year: "numeric" // numeric year
                })}{" "}
                {new Date(row.event_datetime).toLocaleTimeString("en-US", {
                  hour: "numeric", // two-digit hour
                  minute: "numeric", // two-digit minute
                  hour12: true // use 12-hour clock (AM/PM)
                })}{" "}
              </small>
              &bull; <TimeSince date={new Date(row.event_datetime)} />
            </p>
          </CCol>
        </CRow>
      </CCardHeader>
      {showDetails || state.expandAll ? (
        <ActivityCardBody modelId={modelId} modelName={modelName} row={row} />
      ) : null}
    </CCard>
  );
};

const ActivityCard = ({ modelName, modelId, modelEventUpdatedAt, user }) => {
  const [hasData, setHasData] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const [filterRole, setFilterRole] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);

  // Need to use context to get children to rerender.
  // Analytics.EventsQueryPaged / EventsQueryControlled doesnt re-render unless the query changes.
  // So if we render a component as a child, and it uses state from here: ActivityCard, it wont re-render when the state changes, since EventsQueryControlled blocks it. So we use context and pass any necessary state thorough it to children.

  let where = ` and event_category IN ([${filterCategories
    .map((c) => `'${c}'`)
    .join(",")}])`;

  if (filterCategories.length < 1) {
    where = "";
  }

  if (filterRole && filterRole !== "false") {
    where += ` and role = '${filterRole}'`;
  }

  return (
    <ModelEventsContext.Provider
      value={{
        expandAll
      }}
    >
      <Analytics.DateSelection.Provider initialPreset="all_time">
        <CCard className="mb-4">
          <CCardHeader>
            <CRow>
              <CCol className="v-center">Activity</CCol>
              <CCol className="v-center" xs="auto">
                <a
                  href="#"
                  style={{ visibility: hasData ? "visible" : "hidden" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setExpandAll(!expandAll);
                  }}
                >
                  {expandAll ? "Collapse" : "Expand"}
                </a>
              </CCol>
              <CCol xs="auto">
                <Analytics.DateSelection.Picker />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <Analytics.EventsQueryControlled
                  select="distinct event_category"
                  where={`model_owner = '${modelName}' and model_id = '${modelId}' /* ${modelEventUpdatedAt?.seconds} */`}
                  orderBy="event_datetime desc"
                  limit={10}
                  onLoad={({ data }) => {
                    if (data.length > 0) {
                      setHasData(true);
                    }
                  }}
                >
                  {({ isLoading, data }) => (
                    // CategoryButtons keeps its own state
                    // It also updates this components state.
                    // Because this does not rerender as a child of EventsQueryControlled
                    <CategoryButtons
                      categories={data}
                      onFilterCategory={(r) => {
                        setFilterCategories(r);
                      }}
                    />
                  )}
                </Analytics.EventsQueryControlled>
              </CCol>
              <CCol xs={4}>
                <CFormSelect
                  options={[
                    { label: "All roles", value: "false" },
                    { label: "User", value: "user" },
                    { label: "Partner", value: "partner" },
                    { label: "Integration", value: "integration" }
                  ]}
                  value={filterRole}
                  onChange={(e) => {
                    setFilterRole(e.target.value);
                  }}
                />
              </CCol>
            </CRow>
            <hr />
            <Analytics.EventsQueryPaged
              query={{
                select: `id, uid, event_category, event_name, ip, user_agent, metadata, message, role, %event_datetime% /*${modelEventUpdatedAt?.seconds}*/`,
                where: `model_owner = '${modelName}' and model_id = '${modelId}' ${where}`,
                orderBy: "event_datetime desc",
                limit: 10
              }}
            >
              {(data) => (
                // Children of EventsQueryControlled does not rerender unless the query changes.
                // So rendering child components based off state will not work.
                <Analytics.DataList
                  {...data}
                  renderRow={(row) => (
                    <Row
                      modelName={modelName}
                      modelId={modelId}
                      row={row}
                      user={user}
                    />
                  )}
                  renderNoData={() => <li>none</li>}
                />
              )}
            </Analytics.EventsQueryPaged>
          </CCardBody>
        </CCard>
      </Analytics.DateSelection.Provider>
    </ModelEventsContext.Provider>
  );
};

export default ActivityCard;
