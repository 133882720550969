import FirestoreModel from "models/Firestore";
import Firestore from "utils/Firestore";

import Event from "models/Event";
import UserCourseCache from "models/UserCourseCache";

const COLLECTION_NAME = "user_courses";

class UserCourse extends FirestoreModel {
  constructor(authContext) {
    super(COLLECTION_NAME, authContext);
  }

  /**
   * Assign a single course to a user.
   */
  async create({ user_id, course_id }) {
    Event.create({
      model_owner: "user",
      model_id: user_id,
      event_category: "course_access",
      event_name: "create",
      metadata: { course_ids: [course_id] }
    });

    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.add(user_id, course_id);

    return this.add({
      user_id,
      course_id,
      is_deleted: false,
      is_favorite: false,
      index: +new Date(),
      created_at: this.FieldValue.serverTimestamp()
    });
  }

  /**
  Create multiple user course documents for a userID.
  userId - string
  courseIdList - Array<String> - array of course ids.
  */
  async createMultiple(userId, courseIdList) {
    const batch = this.batch();
    courseIdList.forEach((courseId) => {
      batch.set(this.docRef(), {
        user_id: userId,
        course_id: courseId,
        is_deleted: false,
        is_favorite: false,
        index: +new Date(),
        created_at: this.FieldValue.serverTimestamp()
      });
    });
    await batch.commit();

    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.add(userId, courseIdList);

    await Event.create({
      model_owner: "user",
      model_id: userId,
      event_category: "course_access",
      event_name: "create",
      metadata: { course_ids: courseIdList }
    });
  }

  /**
  Set the is_deleted flag.
  userId - string
  courseId -  string
  userCourseId - string
  */
  async softDelete(userId, courseId, userCourseId) {
    Event.create({
      model_owner: "user",
      model_id: userId,
      event_category: "course_access",
      event_name: "remove",
      metadata: { course_ids: [courseId] }
    });

    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.remove(userId, courseId);

    return super.update(userCourseId, {
      is_deleted: true
    });
  }

  /**
  Bulk set the is_deleted flag.
  userId - string
  courseIds - [string]
  userCourseIds - [string]
  */
  async bulkSoftDelete(userId, courseIds, userCourseIds = []) {
    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.remove(userId, courseIds);

    const batch = this.batch();
    userCourseIds.forEach((userCourseId) => {
      const ref = Firestore.db.collection(COLLECTION_NAME).doc(userCourseId);
      batch.update(ref, { is_deleted: true });
    });
    await batch.commit();

    Event.create({
      model_owner: "user",
      model_id: userId,
      event_category: "course_access",
      event_name: "bulk_remove",
      metadata: { course_ids: courseIds }
    });
  }

  /**
   * Move user courses to selected user
   * original_uid = user id of original user that is being viewed
   * selected_uid = user id of selected user
   * return list of courses of selected user
   */
  async moveUserCourses(original_uid, selected_uid) {
    const batch = this.batch();
    // get all courses of selected user
    const courses = await Firestore.db
      .collection(COLLECTION_NAME)
      .where("user_id", "==", selected_uid)
      .where("is_deleted", "==", false)
      .get();
    // move all courses to the original user

    const courseIdList = [];

    courses.docs.forEach((doc) => {
      batch.update(doc.ref, { user_id: original_uid });
      courseIdList.push(doc.id);
    });
    await batch.commit();

    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.add(original_uid, courseIdList);

    return courses;
  }
  
  /**
   * Update the index to the current unix timestamp.
   */
  updateIndexToTimestamp(id) {
    // Do not update the index if we're spying on a user.
    if (this.authContext.is_spying_on_user) {
      return;
    }

    return this.update(id, {
      index: +new Date()
    });
  }

  resetCourseProgress(id) {
    return this.update(id, {
      lesson_progress: {}
    });
  }

  async subscribe(user_id, course_id, docId) {
    const userCourseCache = new UserCourseCache(this.authContext);
    await userCourseCache.add(user_id, course_id);
    return this.set(docId, {
      user_id,
      course_id,
      is_deleted: false,
      is_favorite: false,
      is_subscribe: true,
      index: 0,
      created_at: Firestore.FieldValue.serverTimestamp()
    });
  }

  createDocId() {
    return this.colRef().doc().id;
  }
}

export default UserCourse;
