import React, { useContext } from "react";
import Context from "./Context";

export default function useAuth() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("useAuth() must be used within a Auth.Provider");
  }
  return context;
}
