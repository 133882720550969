/**
Render the metadata for any shopify event.

Handles all models/events.
*/
import React from "react";
import { CRow, CCol, CButton, CAlert, CBadge } from "@coreui/react";

import CourseDocument from "models/Course/Document";

const RenderShopifyMetadata = ({ row, metadata }) => {
  const { event_category, event_datetime, event_name } = row;

  let title = null;

  // Default is to render as JSON.
  let renderContent = null;

  if (event_category === "purchase" && event_name === "create") {
    renderContent = <PurchasedList metadata={metadata} />;
  } else if (event_category === "purchase" && event_name === "refund") {
    renderContent = (
      <RefundDetails user={metadata?.user} payload={metadata?.payload} />
    );
  }

  return (
    <>
      <CRow className="mt-3">
        <CCol>{renderContent}</CCol>
      </CRow>
    </>
  );
};

export default RenderShopifyMetadata;

const RefundItem = ({ refund, user }) => {
  const name = `${user.first_name} ${user.last_name}`;
  const amount = parseFloat(refund.line_item.price).toFixed(2);
  const currency =
    refund?.line_item?.price_set?.presentment_money.currency_code;
  const { title } = refund.line_item;

  return (
    <div className="mb-2">
      <strong>{name}</strong> refunded{" "}
      <strong>
        {amount} {currency}
      </strong>{" "}
      for {title}
    </div>
  );
};

const RefundDetails = ({ user, payload }) => (
  <div>
    {payload.refund_line_items.map((refund) => (
      <RefundItem refund={refund} key={refund.id} user={user} />
    ))}
  </div>
);

const PurchasedList = ({ metadata }) => {
  // Upsells will have a payload of:
  // {order: ShopifyOrderObject, order_edit: ShopifyOrderEditObject
  const order = metadata.payload.order || metadata.payload;
  const orderEdit = metadata.payload.order_edit || null;

  const courseIds = metadata.course_ids;

  return (
    <>
      {orderEdit ? (
        <CAlert color="warning">
          This order was modified because the customer purchased an upsell or
          downsell. This event will grant only the new courses. Previous courses
          will have been granted in an earlier event.
        </CAlert>
      ) : null}
      <CRow className="mb-2">
        <CCol>
          Order Number:{" "}
          <strong>
            <a
              href={`https://admin.shopify.com/store/setthehookonline/orders/${order.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {order.id}
            </a>
          </strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase date:{" "}
          <strong>
            {new Date(order.created_at).toLocaleDateString()}{" "}
            {new Date(order.created_at).toLocaleTimeString("en-US", {
              hour: "numeric", // two-digit hour
              minute: "numeric", // two-digit minute
              hour12: true // use 12-hour clock (AM/PM)
            })}
          </strong>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol>
          Purchase amount:{" "}
          <strong>
            ${order.total_price_set.presentment_money.amount}{" "}
            {order.total_price_set.presentment_money.currency_code.toUpperCase()}
          </strong>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Products:
          <ul>
            {order.line_items.map((lineItem) => {
              // Determine if this is an edtied order.
              // If so, show a badge on the added items.
              let showBadge =
                orderEdit &&
                orderEdit.line_items.additions
                  .map(({ id }) => id)
                  .includes(lineItem.id);

              return (
                <li key={lineItem.id}>
                  {showBadge ? (
                    <CBadge color="success" style={{ marginRight: 5 }}>
                      Added
                    </CBadge>
                  ) : null}
                  {lineItem.title} -{" "}
                  {(
                    parseFloat(lineItem.price_set.presentment_money.amount) -
                    parseFloat(
                      lineItem.total_discount_set.presentment_money.amount
                    )
                  ).toFixed(2)}{" "}
                  {lineItem?.price_set?.presentment_money?.currency_code}
                </li>
              );
            })}
          </ul>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          Assigned courses:
          <ul>
            {(courseIds || []).map((courseId) => (
              <li key={courseId}>
                <a
                  href={`/manage/courses/${courseId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CourseDocument id={courseId}>
                    {({ isLoading, data }) => {
                      if (isLoading || !data) {
                        return null;
                      }
                      return `${data.title}`;
                    }}
                  </CourseDocument>
                </a>
              </li>
            ))}
          </ul>
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol>
          <a href={order.order_status_url} target="_blank" rel="noreferrer">
            View shopify receipt
          </a>
        </CCol>
      </CRow>
    </>
  );
};
