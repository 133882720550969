/**
Renders single child with {isLoading,isUpdating,data,error,...other} from EventsQueryControlled passed as props.

If child is a function, renders the function.
*/
import React from "react";

import {
  Pagination,
  DataTable,
  EventsQueryControlled
} from "components/Analytics";

import _ from "underscore";

const EventsQueryPaged = (props) => {
  const {
    query, // passed to EventsQueryControlled
    simplePaginator
  } = props;

  const countQueryProps = _.pick(query, [
    "select",
    "where",
    "groupBy",
    "having"
  ]);

  return (
    <Pagination.Provider>
      <EventsQueryControlled {...query}>
        {(data) => {
          if (typeof props.children === "function") {
            return props.children(data);
          }

          const singleChild = React.Children.only(props.children);
          const clonedChild = React.cloneElement(singleChild, {
            ...data,
            ...singleChild.props
          });
          return clonedChild;
        }}
      </EventsQueryControlled>
      {typeof query.limit === "number" && !isNaN(query.limit) ? (
        <EventsQueryControlled
          {...countQueryProps}
          parentQuery="select count(*) as total_rows "
        >
          {({ data, isUpdating }) => {
            /* Query the total number of rows so we know total pages and records. */
            if (!data || data.length < 1) {
              return null;
            }
            const totalRows = Object.values(data[0])[0];
            const pageCount = totalRows / query.limit;
            if (pageCount < 1) {
              return null;
            }
            return (
              <>
                <Pagination.Paginator
                  isUpdating={isUpdating}
                  simple={simplePaginator}
                  pageCount={pageCount}
                  rowCount={totalRows}
                  resultsPerPage={query.limit}
                />
              </>
            );
          }}
        </EventsQueryControlled>
      ) : null}
    </Pagination.Provider>
  );
};

export default EventsQueryPaged;
