import React from "react";

import UserInterfaceContext from "./Context";

const UserInterfaceConsumer = (props) => (
    <UserInterfaceContext.Consumer>
      {props.children}
    </UserInterfaceContext.Consumer>
  );

export default UserInterfaceConsumer;
