/**
 * Given dates, determine if it matches a preset.
 * This is so when dates are selected in the picker, we can show a nice name.
 * Returns null if no preset found.
 */

/*
const isSameMonth = (date) => {
  const today = new Date();
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth()
  );
};

const isLastMonth = (date) => {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  return (
    lastMonth.getFullYear() === date.getFullYear() &&
    lastMonth.getMonth() === date.getMonth()
  );
};
*/

const isToday = (date) => {
  const today = new Date();

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    yesterday.getFullYear() === date.getFullYear() &&
    yesterday.getMonth() === date.getMonth() &&
    yesterday.getDate() === date.getDate()
  );
};

/**
 * returns boolean - true if the date is numDays ago
 */
const isNumDaysAgo = (date, numDays) => {
  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setDate(currentDate.getDate() - numDays);

  return inputDate.getTime() === currentDate.getTime();
};

/**
 * returns boolean - true if the date range is equal to the last week.
 */
const isLastWeek = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the current date and set time to 00:00:00
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  // Get the last Saturday
  const lastSaturday = new Date(now);
  lastSaturday.setDate(now.getDate() - now.getDay() - 1);
  lastSaturday.setHours(23, 59, 59, 0);

  // Get the previous Sunday
  const previousSunday = new Date(lastSaturday);
  previousSunday.setDate(lastSaturday.getDate() - 6);
  previousSunday.setHours(0, 0, 0, 0);

  return (
    start.getTime() === previousSunday.getTime() &&
    end.getTime() === lastSaturday.getTime()
  );
};

/**
 * returns boolean - true if the date range is equal to the current month.
 */
const isThisMonth = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the current date and set time to 00:00:00
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  // Get the first day of the current month
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 0);

  return (
    start.getTime() === firstDayOfMonth.getTime() &&
    end.getTime() === endOfDay.getTime()
  );
};

/**
 * returns boolean - true if the date range is equal to the last month.
 */
const isLastMonth = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the current date and set time to 00:00:00
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  // Get the first day of the last month
  const firstDayOfLastMonth = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    1
  );

  // Get the last day of the last month
  const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
  lastDayOfLastMonth.setHours(23, 59, 59, 0);

  return (
    start.getTime() === firstDayOfLastMonth.getTime() &&
    end.getTime() === lastDayOfLastMonth.getTime()
  );
};

/*
 * return boolean - true if the date range is all time. start date is Jan 1 1970 to end of day today.
 */
const isAllTime = (startDate, endDate) => {
  // The start of "all time" - Jan 1, 1970
  const allTimeStart = new Date(1970, 0, 1);

  // The end of "all time" - end of today
  const now = new Date();
  const allTimeEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  );

  // Compare given range to "all time"
  return (
    startDate.getTime() === allTimeStart.getTime() &&
    endDate.getTime() === allTimeEnd.getTime()
  );
};

const determinePresetFromDates = (start, end) => {
  if (!start || !end) {
    return null;
  }

  // never need to check last_hour, cant be selected on the calendar.

  if (isToday(start) && isToday(end)) {
    return "today";
  } if (isYesterday(start) && isYesterday(end)) {
    return "yesterday";
  } if (isNumDaysAgo(start, 7) && isYesterday(end)) {
    return "last_7";
  } if (isNumDaysAgo(start, 14) && isYesterday(end)) {
    return "last_14";
  } if (isNumDaysAgo(start, 30) && isYesterday(end)) {
    return "last_30";
  } if (isToday(end) && isNumDaysAgo(start, 6)) {
    return "this_week";
  } if (isLastWeek(start, end)) {
    return "last_week";
  } if (isThisMonth(start, end)) {
    return "this_month";
  } if (isLastMonth(start, end)) {
    return "last_month";
  } if (isAllTime(start, end)) {
    return "all_time";
  }
  return null;
};

export default determinePresetFromDates;
