import Consumer from "./Consumer";
import Provider from "./Provider";
import Context from "./Context";
import useEditor from "./useEditor";

import EditManager from "./EditManager";
import EditModal from "./EditModal";

const exportable = {
  Consumer,
  Provider,
  Context,
  EditManager,
  EditModal,
  useEditor
};
export default exportable;

export { useEditor };
