/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/**
Render the metadata for any stripe event.

Handles all models/events.
*/
import React from "react";
import { CRow, CCol, CButton } from "@coreui/react";
import Stripe from "utils/Stripe";

import CourseDocument from "models/Course/Document";

const RefundDetails = ({ payload, user }) => {
  const handleViewReceipt = (receiptUrl) => {
    window.open(receiptUrl, "_blank");
  };

  return (
    <>
      <CRow className="mb-2">
        <CCol>
          Transaction ID: <strong>{payload.id}</strong>
        </CCol>
      </CRow>

      <CRow className="mb-2">
        <CCol>
          <strong>
            {user.first_name} {user.last_name}
          </strong>{" "}
          refunded{" "}
          <strong>
            {Stripe.formatAmount(payload?.amount_refunded)} {payload.currency}{" "}
          </strong>
        </CCol>
      </CRow>
      {/* <CRow className="mb-2">
        <CCol>
          <CButton onClick={() => handleViewReceipt(payload.receipt_url)}>
            View Receipt
          </CButton>
        </CCol>
      </CRow> */}
    </>
  );
};

const PurchaseList = ({ transactionId, charges, lineItems, courseIds }) => {
  const handleViewReceipt = (receiptUrl) => {
    window.open(receiptUrl, "_blank");
  };

  return (
    <>
      <CRow className="mb-2">
        <CCol>
          Transaction ID:{" "}
          <strong>
            <a
              href={`https://dashboard.stripe.com/payments/${transactionId}`}
              target="_blank"
              rel="noreferrer"
            >
              {transactionId}
            </a>
          </strong>
        </CCol>
      </CRow>
      {charges.map((charge) => (
        <div key={charge.id} className="mb-2">
          <CRow className="mb-2">
            <CCol>
              Purchase date:{" "}
              <strong>
                {new Date(charge.created * 1000).toLocaleDateString()}{" "}
                {new Date(charge.created * 1000).toLocaleTimeString("en-US", {
                  hour: "numeric", // two-digit hour
                  minute: "numeric", // two-digit minute
                  hour12: true // use 12-hour clock (AM/PM)
                })}
              </strong>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              Purchase amount:{" "}
              <strong>
                ${Stripe.formatAmount(charge.amount_captured)}{" "}
                {charge.currency.toUpperCase()}
              </strong>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              Products:
              <br />
              <ul>
                {(lineItems || []).map((lineItem) => {
                  return (
                    <li key={lineItem.product_id}>
                      {lineItem.product_name}
                      {" - "}${Stripe.formatAmount(lineItem.price_cents)}
                      <br />
                    </li>
                  );
                })}
              </ul>
            </CCol>
          </CRow>
          {charge.description && false && (
            <CRow className="mb-2">
              <CCol>
                Description: <strong>{charge.description}</strong>
              </CCol>
            </CRow>
          )}
        </div>
      ))}
      <CRow>
        <CCol>
          Assigned courses:
          <br />
          <ul>
            {(courseIds || []).map((courseId) => {
              return (
                <li key={courseId}>
                  <a
                    href={`/manage/courses/${courseId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CourseDocument id={courseId}>
                      {({ isLoading, data }) => {
                        if (isLoading || !data) {
                          return null;
                        }
                        return `${data.title}`;
                      }}
                    </CourseDocument>
                  </a>
                </li>
              );
            })}
          </ul>
        </CCol>
      </CRow>
    </>
  );
};

const DisputeDetails = ({ user, payload, charge }) => {
  const products = Object.keys(charge?.metadata || {})
    .filter((key) => key.startsWith("product_id"))
    .map((key) => {
      const index = key.split("_")[2];
      return {
        id: charge.metadata[`product_id_${index}`],
        name: charge.metadata[`product_name_${index}`],
        amount: charge.metadata[`amount_${index}`]
      };
    });

  const handleViewReceipt = (receiptUrl) => {
    window.open(receiptUrl, "_blank");
  };

  return (
    <>
      <CRow className="mb-2">
        <CCol>
          Transaction ID:{" "}
          <a
            href={`https://dashboard.stripe.com/payments/${payload.charge}`}
            target="_blank"
            rel="noreferrer"
          >
            {payload?.charge}
          </a>
        </CCol>
      </CRow>

      {products.map((product) => (
        <div className="mb-2" key={product.id}>
          <CRow className="mb-2">
            <CCol>
              Amount:{" "}
              <strong>
                {Stripe.formatAmount(product.amount)} {charge.currency}
              </strong>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              Product: <strong>{product.name}</strong>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol>
              <CButton onClick={() => handleViewReceipt(charge.receipt_url)}>
                View Receipt
              </CButton>
            </CCol>
          </CRow>
        </div>
      ))}
    </>
  );
};

const RenderStripeMetaData = ({ row, metadata }) => {
  const { event_category, event_datetime, event_name } = row;

  // Default is to render as JSON.
  let renderContent = null;

  if (event_category === "purchase" && event_name === "create") {
    renderContent = (
      <PurchaseList
        transactionId={metadata?.payload?.id}
        charges={metadata?.payload?.charges?.data || []}
        lineItems={metadata?.lineItems}
        courseIds={metadata?.course_ids}
      />
    );
  } else if (event_category === "purchase" && event_name === "refund") {
    renderContent = (
      <RefundDetails user={metadata?.user} payload={metadata?.payload} />
    );
  } else if (event_category === "purchase" && event_name === "dispute") {
    renderContent = (
      <DisputeDetails
        user={metadata?.user}
        payload={metadata?.payload}
        charge={metadata?.charge}
      />
    );
  }

  return (
    <>
      <CRow className="mt-3">
        <CCol>{renderContent}</CCol>
      </CRow>
    </>
  );
};

export default RenderStripeMetaData;
