import React from "react";
import { CRow, CCol } from "@coreui/react";

import CourseDocument from "models/Course/Document";

const RenderCourseAlreadyGranted = ({ row }) => {
  const metadata = JSON.parse(row.metadata || "{}");

  return (
    <CRow className="mb-2">
      <CCol>
        Courses already granted:
        <ul>
          {(metadata.course_ids || []).map((courseId) => (
            <li key={courseId}>
              <a
                href={`/manage/courses/${courseId}`}
                target="_blank"
                rel="noreferrer"
              >
                <CourseDocument id={courseId}>
                  {({ isLoading, data }) => {
                    if (isLoading || !data) {
                      return null;
                    }
                    return `${data.title}`;
                  }}
                </CourseDocument>
              </a>
            </li>
          ))}
        </ul>
      </CCol>
    </CRow>
  );
};

export default RenderCourseAlreadyGranted;
