import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { DEV } from "constants";

class UserAccount {
  constructor(authContext) {
    this.authContext = authContext;
  }

  static getAccountEmail({ partner_id, brand_id, email }) {
    let devPrefix = "";
    if (DEV) {
      devPrefix = "DEV_";
    }

    if (brand_id) {
      return `${devPrefix}${partner_id}_${brand_id}_${email}`;
    }
    // No brand, just partner_email
    return `${devPrefix}${partner_id}_${email}`;
  }

  // Create a new user
  /*
  signUp(email, password, brandId) {
    const auth = firebase.auth();

    // firebase createUserWithEmailAndPassword authenticates the user after creation.
    // Not what we want. Create the user on the backend.

    return auth.createUserWithEmailAndPassword(
      UserAccount.getAccountEmail({
        partner_id: this.authContext.user.partner_id,
        brand_id: brandId,
        email
      }),
      password
    );
  }
  */
}

export default UserAccount;
